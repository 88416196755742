import { fetchBaseQuery, retry } from '@reduxjs/toolkit/query';
import { v4 } from 'uuid';
import type { RootState } from 'app/types';
import { API_URL } from 'app/shared/constants/api.constants';
import { getToken } from 'app/redux/auth/helpers';

interface Configuration {
  withAuth: boolean;
}

const customBackoff = async (attempt: number = 0, maxRetries: number = 5) => {
  const attempts = Math.min(attempt, maxRetries);
  const timeout = ~~((Math.random() + 0.5) * (400 << attempts));
  await new Promise((resolve) => setTimeout((res: any) => resolve(res), timeout));
};

const baseQuery = ({ withAuth }: Configuration) =>
  retry(
    fetchBaseQuery({
      baseUrl: `${API_URL}/`,
      timeout: 10000, // Default timeout
      prepareHeaders: (headers, { getState }) => {
        const state = getState() as RootState;
        const locale = state.intl.locale;
        const token = getToken();

        // General headers
        headers.set('Accept-Language', locale);
        headers.set('X-Correlation-ID', v4());

        if (withAuth && token) {
          // Authorization headers
          headers.set('Authorization', `Bearer ${token}`);
        }

        return headers;
      },
    }),
    { maxRetries: 0, backoff: customBackoff },
  );

export const baseQueryWithAuth = baseQuery({ withAuth: true });

/**
 * This version of baseQuery is dedicated for cases where the token exists, but we don't want to use it.
 */
export const baseQueryWithoutAuth = baseQuery({ withAuth: false });
