import uniqBy from 'lodash/uniqBy';
import { FormErrors } from 'redux-form';
import {
  dateTodayOrInPast,
  email,
  governmentIssuedId,
  name,
  nonArabic,
  onlyArabic,
  phoneNumber,
  required,
} from 'app/shared';
import { PatientPhoneNumberProps } from 'app/types';
import { getIsRequiredFlags } from 'app/features/patient/patientForm/helpers/getIsRequiredFlags';
import {
  PatientFormData,
  PatientFormErrorType,
  PatientFormField,
  ValidatorType,
} from 'app/features/patient/patientForm/types';

export const formValidator = (
  values: PatientFormData,
  validatorType: ValidatorType,
): FormErrors<PatientFormData, PatientFormErrorType> => {
  const errors: FormErrors<PatientFormData, PatientFormErrorType> = {};

  const { isGrandFatherNameRequired, isFatherNameRequired } = validatorType;

  const firstNameArValue = values?.[PatientFormField.firstNameAr];
  const familyNameArValue = values?.[PatientFormField.familyNameAr];
  const fatherNameArValue = values?.[PatientFormField.fatherNameAr];
  const grandFatherNameArValue = values?.[PatientFormField.grandFatherNameAr];
  const firstNameEnValue = values?.[PatientFormField.firstNameEn];
  const familyNameEnValue = values?.[PatientFormField.familyNameEn];
  const fatherNameEnValue = values?.[PatientFormField.fatherNameEn];
  const grandFatherNameEnValue = values?.[PatientFormField.grandFatherNameEn];

  const {
    isFirstNameArRequired,
    isFirstNameEnRequired,
    isFamilyNameArRequired,
    isFamilyNameEnRequired,
    isFatherNameArRequired,
    isFatherNameEnRequired,
    isGrandFatherNameArRequired,
    isGrandFatherNameEnRequired,
  } = getIsRequiredFlags(values, isFatherNameRequired, isGrandFatherNameRequired);

  if (isFirstNameArRequired) {
    if (!firstNameArValue) {
      errors.firstNameAr = required(firstNameArValue);
    } else {
      errors.firstNameAr = onlyArabic(firstNameArValue);
    }
  } else {
    if (firstNameArValue) {
      errors.firstNameAr = onlyArabic(firstNameArValue);
    }
  }

  if (isFirstNameEnRequired) {
    if (!firstNameEnValue) {
      errors.firstNameEn = required(firstNameEnValue);
    } else {
      errors.firstNameEn = nonArabic(firstNameEnValue);
      errors.firstNameEn = name(firstNameEnValue);
    }
  } else {
    if (firstNameEnValue) {
      errors.firstNameEn = nonArabic(firstNameEnValue);
      errors.firstNameEn = name(firstNameEnValue);
    }
  }

  if (isFamilyNameArRequired) {
    if (!familyNameArValue) {
      errors.familyNameAr = required(familyNameArValue);
    } else {
      errors.familyNameAr = onlyArabic(familyNameArValue);
    }
  } else {
    if (familyNameArValue) {
      errors.familyNameAr = onlyArabic(familyNameArValue);
    }
  }

  if (isFamilyNameEnRequired) {
    if (!familyNameEnValue) {
      errors.familyNameEn = required(familyNameEnValue);
    } else {
      errors.familyNameEn = nonArabic(familyNameEnValue);
      errors.familyNameEn = name(familyNameEnValue);
    }
  } else {
    if (familyNameEnValue) {
      errors.familyNameEn = nonArabic(familyNameEnValue);
      errors.familyNameEn = name(familyNameEnValue);
    }
  }

  if (isFatherNameArRequired) {
    if (!fatherNameArValue) {
      errors.fatherNameAr = required(fatherNameArValue);
    } else {
      errors.fatherNameAr = onlyArabic(fatherNameArValue);
    }
  } else {
    if (fatherNameArValue) {
      errors.fatherNameAr = onlyArabic(fatherNameArValue);
    }
  }

  if (isFatherNameEnRequired) {
    if (!fatherNameEnValue) {
      errors.fatherNameEn = required(fatherNameEnValue);
    } else {
      errors.fatherNameEn = nonArabic(fatherNameEnValue);
      errors.fatherNameEn = name(fatherNameEnValue);
    }
  } else {
    if (fatherNameEnValue) {
      errors.fatherNameEn = nonArabic(fatherNameEnValue);
      errors.fatherNameEn = name(fatherNameEnValue);
    }
  }

  if (isGrandFatherNameArRequired) {
    if (!grandFatherNameArValue) {
      errors.grandFatherNameAr = required(grandFatherNameArValue);
    } else {
      errors.grandFatherNameAr = onlyArabic(grandFatherNameArValue);
    }
  } else {
    if (grandFatherNameArValue) {
      errors.grandFatherNameAr = onlyArabic(grandFatherNameArValue);
    }
  }

  if (isGrandFatherNameEnRequired) {
    if (!grandFatherNameEnValue) {
      errors.grandFatherNameEn = required(grandFatherNameEnValue);
    } else {
      errors.grandFatherNameEn = nonArabic(grandFatherNameEnValue);
      errors.grandFatherNameEn = name(grandFatherNameEnValue);
    }
  } else {
    if (grandFatherNameEnValue) {
      errors.grandFatherNameEn = nonArabic(grandFatherNameEnValue);
      errors.grandFatherNameEn = name(grandFatherNameEnValue);
    }
  }

  if (values?.preferredNameAr) {
    errors.preferredNameAr = onlyArabic(values.preferredNameAr);
  }

  if (values?.preferredNameEn) {
    errors.preferredNameEn = nonArabic(values.preferredNameEn);
    errors.preferredNameEn = name(values.preferredNameEn);
  }

  if (values?.email) {
    errors.email = email(values.email);
  }

  if (values?.dateOfBirth) {
    errors.dateOfBirth = dateTodayOrInPast(values.dateOfBirth);
  }

  if (!values?.dateOfBirth) {
    errors.dateOfBirth = required(values.dateOfBirth);
  }

  if (!values?.gender) {
    errors.gender = required(values.gender);
  }

  if (!values?.typeOfGovernmentIssuedId) {
    errors.typeOfGovernmentIssuedId = required(values.typeOfGovernmentIssuedId);
  }

  if (!values?.governmentIssuedIdNumber) {
    errors.governmentIssuedIdNumber = required(values.governmentIssuedIdNumber);
  }

  if (values?.typeOfGovernmentIssuedId && values?.governmentIssuedIdNumber) {
    errors.governmentIssuedIdNumber = governmentIssuedId(values.typeOfGovernmentIssuedId.value)(
      values.governmentIssuedIdNumber,
    );
  }

  if (!values?.preferredLanguage) {
    errors.preferredLanguage = required(values.preferredLanguage);
  }

  if (!values?.otherMaritalStatus) {
    errors.otherMaritalStatus = required(values.otherMaritalStatus);
  }

  if (!!values.emergencyContactName?.trim() && !values?.emergencyContactRelation) {
    errors.emergencyContactRelation = required(values.emergencyContactRelation);
  }

  if (!!values.emergencyContactName?.trim() && !values?.otherEmergencyContactRelation) {
    errors.otherEmergencyContactRelation = required(values.otherEmergencyContactRelation);
  }

  if (!!values.emergencyContactName?.trim() && !values?.emergencyContactNumber) {
    errors.emergencyContactNumber = required(values.emergencyContactNumber);
  }

  if (values?.emergencyContactNumber) {
    errors.emergencyContactNumber = phoneNumber(values.emergencyContactNumber);
  }

  if (!values?.patientMobileNumbers) {
    errors.patientMobileNumbers = required(values.patientMobileNumbers);
  }

  // cross-check Preferred Name field (if one is filled, then both should be)
  if (values?.preferredNameAr && !values?.preferredNameEn) {
    errors.preferredNameEn = required(values?.preferredNameEn);
  }
  if (values?.preferredNameEn && !values?.preferredNameAr) {
    errors.preferredNameAr = required(values?.preferredNameAr);
  }

  if (values?.patientMobileNumbers?.length > 1) {
    // check for phone number uniqueness when more than one mobile number set
    const uniqueNumbers = uniqBy(values.patientMobileNumbers, PatientPhoneNumberProps.phoneNumber);
    if (values.patientMobileNumbers.length !== uniqueNumbers.length) {
      errors.patientMobileNumbers = { _error: 'ERRORS.NOT-UNIQUE-MOBILE-NUMBER' };
    }
  }

  if (values?.primaryPatients && values?.primaryPatients.length === 0) {
    errors.primaryPatients = required(values?.primaryPatients);
  }

  if (values?.primaryPatients && values.primaryPatients.length > 0) {
    if (values.primaryPatients.some((i) => !i.relation)) {
      errors.primaryPatients = { _error: 'ERRORS.NOT-SPECIFIED-RELATION' };
    }
  }

  return errors;
};
