import React, { useMemo } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import { checkAccess, formattedDate, getTranslation, useAppIntl, useAppSelector } from 'app/helpers';
import { InsuranceStatusBadge } from 'app/components';
import { MergeBadge } from 'app/components/mergeBadge/mergeBadge';
import { InsuranceDetailsItem, Locale, UserRoles } from 'app/types';
import { ActionsFormatter } from 'app/features/patient/patientInsuranceDetailsForm/formatters/actionFormatter';
import { policyNumberFormatter } from 'app/features/patient/patientInsuranceDetailsForm/helpers/policyNumberFormatter';

const allowedRoles = [
  UserRoles.Manager,
  UserRoles.ManagerAdmin,
  UserRoles.MasterAdmin,
  UserRoles.Reception,
  UserRoles.ReceptionAdmin,
  UserRoles.Owner,
];

export interface Props {
  onEdit?: (patientProfileInsurance: InsuranceDetailsItem) => void;
  onDelete?: (patientProfileInsurance: InsuranceDetailsItem) => void;
  isGlobal?: boolean;
  isMergedOrPatientDeleted?: boolean;
  allowedForInsuranceManagement?: boolean;
}

export const useColumns = ({
  onEdit,
  onDelete,
  isGlobal,
  isMergedOrPatientDeleted,
  allowedForInsuranceManagement,
}: Props) => {
  const { formatMessage, locale } = useAppIntl();

  // Selectors
  const userRoles = useAppSelector((state) => state.auth.current.roles);

  const shouldDisplayActions = useMemo<boolean>(
    () => !!onEdit && !!onDelete && checkAccess(allowedRoles, userRoles),
    [onDelete, onEdit, userRoles],
  );

  return useMemo<ColumnDescription<InsuranceDetailsItem>[]>(
    () => [
      {
        dataField: 'medicalInstitution',
        formatter: (_, row) => getTranslation(row.clinic, locale) || '-',
        text: formatMessage({ id: 'CORE.LABEL.MEDICAL-INSTITUTION' }),
        hidden: !isGlobal,
      },
      {
        dataField: 'clinicPatient',
        text: formatMessage({ id: 'CORE.LABEL.CLINIC-PATIENT' }),
        formatter: (_, row) => (
          <Link to={`/clinics/${row.clinic?.value}/patients/${row.clinicPatient?.value}`}>
            {row.clinicPatient?.label}
          </Link>
        ),
        hidden: !isGlobal,
      },
      {
        dataField: 'clinicBranch',
        formatter: (_, row) => (
          <div className="d-flex align-items-center">
            {getTranslation(row.clinicBranch, locale) || '-'}
            <MergeBadge id={row.id} hasMergePatientsBadge={row.hasMergePatientsBadge} />
          </div>
        ),
        text: formatMessage({ id: 'CORE.LABEL.BRANCH-NAME' }),
      },
      {
        dataField: 'insuranceCompany',
        text: formatMessage({ id: 'CORE.LABEL.INSURANCE-COMPANY' }),
        formatter: (_, row) => (locale === Locale.en ? row.insuranceCompany?.nameEn : row.insuranceCompany?.nameAr),
      },
      {
        dataField: 'status',
        text: formatMessage({ id: 'CORE.LABEL.STATUS' }),
        formatter: (_, row) => <InsuranceStatusBadge status={!row?.deactivated} />,
      },
      {
        dataField: `policyNumber`,
        text: formatMessage({ id: 'CORE.LABEL.POLICY-NUMBER' }),
        formatter: policyNumberFormatter,
      },
      {
        dataField: 'number',
        text: formatMessage({ id: 'CORE.LABEL.INSURANCE-NUMBER' }),
      },
      {
        dataField: 'insuranceClass',
        text: formatMessage({ id: 'CORE.LABEL.INSURANCE-CLASS' }),
        formatter: (_, row) => row.insuranceClass?.label || '-',
      },
      {
        dataField: 'start',
        text: formatMessage({ id: 'CORE.LABEL.START-DATE' }),
        formatter: (_, row) => formattedDate(row.start),
      },
      {
        dataField: 'end',
        text: formatMessage({ id: 'INSURANCES.TABLE.EXPIRY-DATE' }),
        formatter: (_, row) => formattedDate(row.end),
      },
      {
        dataField: 'actions',
        text: formatMessage({ id: 'CORE.TABLE.ACTIONS' }),
        formatter: ActionsFormatter,
        formatExtraData: { onEdit, onDelete, formatMessage },
        classes: 'text-center sticky sticky--end',
        headerClasses: 'text-center sticky sticky--end',
        hidden: !shouldDisplayActions || isMergedOrPatientDeleted || !allowedForInsuranceManagement,
      },
    ],
    [
      allowedForInsuranceManagement,
      formatMessage,
      isGlobal,
      isMergedOrPatientDeleted,
      locale,
      onDelete,
      onEdit,
      shouldDisplayActions,
    ],
  );
};
