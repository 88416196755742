import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import { GetTranslationParams, GetTranslationResponse } from 'app/services/general/types';
import { Dictionaries } from 'app/types';

export const generalApi = createApi({
  reducerPath: 'generalApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['Dictionaries'],
  endpoints: (builder) => ({
    getDictionaries: builder.query<Dictionaries, undefined>({
      query: () => ({
        url: 'dictionaries',
      }),
      providesTags: ['Dictionaries'],
      extraOptions: {
        maxRetries: 3,
      },
    }),
    getTranslation: builder.query<GetTranslationResponse, GetTranslationParams>({
      query: (params) => ({
        url: 'translation/google-translate',
        method: 'POST',
        params,
      }),
    }),
  }),
});

export const { useGetDictionariesQuery, useLazyGetTranslationQuery } = generalApi;
