import { getTranslation, useAppIntl, useDictionaries } from 'app/helpers';
import { Option } from 'app/types';

interface Props {
  value: string;
  type: 'patient' | 'invoice';
}

export const PaymentMethodFormatter = (props: Props) => {
  const { locale } = useAppIntl();
  const { 'patient-payment-methods': patientPaymentMethods, 'invoice-payment-methods': invoicePaymentMethods } =
    useDictionaries();
  const methods: Option[] = (props.type === 'patient' ? patientPaymentMethods : invoicePaymentMethods) || [];
  const method = methods?.find((m) => m.value === props.value);

  return method ? getTranslation(method, locale) : '-';
};
