import React, { useCallback, useMemo, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { submit } from 'redux-form';
import { useAppDispatch, useAppIntl, useAppSelector } from 'app/helpers';
import { PrintTemplate } from 'app/components';
import { FORMS_ASSESSMENT_PRINT_MODAL_FORM, FORMS_ASSESSMENT_SIGNATURE_FORM } from 'app/shared';
import { logDoctorAssessmentPrintAction } from 'app/redux/doctorAssessment/doctorAssessment.actions';
import {
  CustomHeaderPrint,
  PrintAssessment,
  PrintVisitAssessment,
} from 'app/features/doctorAssessment/components/medicalProfileSection/components/helpers';
import { PrintModalBody } from 'app/features/doctorAssessment/components/medicalProfileSection/components/modals/printModal/printModalBody';
import { useCheckViewStatus } from 'app/features/doctorAssessment/helpers';
import styles from 'app/features/doctorAssessment/components/medicalProfileSection/components/modals/printModal/printModal.module.scss';

interface Props {
  isOpen: boolean;
  toggle: () => void;
}

export const PrintModal = ({ isOpen, toggle }: Props) => {
  const dispatch = useAppDispatch();
  const contentRef = useRef<HTMLDivElement>(null);
  const { formatMessage } = useAppIntl();

  const signatureUrl = useAppSelector(
    (state) => state.form[FORMS_ASSESSMENT_SIGNATURE_FORM]?.values?.signatureFile.url,
  );
  const assessmentData = useAppSelector((state) => state.doctorAssessment.data);
  const medicalHistory = useAppSelector((state) => state.treatment.generalTreatmentInformation?.data?.medicalHistory);

  const { isCompleted, isContinue, isReadOnly } = useCheckViewStatus(
    assessmentData?.viewStatus,
    assessmentData?.completedAt,
  );

  const submitForm = useCallback(() => {
    dispatch(submit(FORMS_ASSESSMENT_PRINT_MODAL_FORM));
  }, [dispatch]);

  const onSubmit = useCallback(() => {
    toggle();
  }, [toggle]);

  const initialValues = useMemo(() => {
    return {
      vitals: true,
      pastMedicalHistory: true,
      surgicalHistory: true,
      plan: true,
      goal: true,
      referrals: isCompleted,

      pastMedicalHistoryTable: medicalHistory?.patientMedicalIllnesses,
      surgicalHistoryTable: medicalHistory?.previousSurgeries,
    };
  }, [medicalHistory, isCompleted]);

  const onAfterPrint = useCallback(() => {
    assessmentData?.appointmentId && dispatch(logDoctorAssessmentPrintAction(assessmentData.appointmentId));
  }, [assessmentData?.appointmentId, dispatch]);

  const onBeforePrint = useCallback(async () => {
    await signatureUrl;
  }, [signatureUrl]);

  const onPrint = useReactToPrint({ contentRef, onAfterPrint, onBeforePrint, bodyClass: styles.printComponent });

  const onPrintButton = useCallback(() => {
    onPrint();
    submitForm();
  }, [submitForm, onPrint]);

  return (
    <Modal backdrop="static" keyboard={false} size="lg" isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{formatMessage({ id: 'TREATMENT-PLAN.TEXT.PRINT-OPTIONS' })}</ModalHeader>
      <ModalBody>
        <PrintModalBody
          onSubmit={onSubmit}
          initialValues={initialValues}
          canPrintReferrals={isCompleted && !!assessmentData?.referralSection.referrals?.length}
        />
      </ModalBody>
      <ModalFooter className="m-auto">
        <Button type="button" color="secondary" onClick={toggle}>
          {formatMessage({ id: 'CORE.BUTTON.CANCEL' })}
        </Button>
        <div>
          <Button type="submit" color="primary-gradient" onClick={onPrintButton}>
            {formatMessage({ id: 'CORE.BUTTON.PRINT' })}
          </Button>
        </div>
      </ModalFooter>
      <PrintTemplate
        body={isCompleted || isReadOnly || isContinue ? <PrintVisitAssessment /> : <PrintAssessment />}
        ref={contentRef}
        header={<CustomHeaderPrint />}
      />
    </Modal>
  );
};
