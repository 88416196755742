import React, { ChangeEvent, useMemo } from 'react';
import { IconLock, IconTrash } from '@tabler/icons-react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { UncontrolledTooltip } from 'reactstrap';
import { change, Field } from 'redux-form';
import { EventWithDataHandler } from 'redux-form/lib/Field';
import { getPatientFullName, useAppDispatch, useAppIntl, useDictionaries } from 'app/helpers';
import { IconButton } from 'app/components';
import { InputComponent, required, SelectComponent } from 'app/shared';
import { Option, PatientRelation } from 'app/types';
import { PrimaryPatientBaseInfo } from 'app/features/patient/patientForm/types';

export interface Props {
  clinicId: string;
  removeField: (rowIndex: number) => void;
  form: string;
}

export interface FormatExtraDataProps {
  removeField?: (rowIndex: number) => void;
  form?: string;
}

export const useColumns = ({ clinicId, removeField, form }: Props) => {
  const dispatch = useAppDispatch();
  const { formatMessage, locale } = useAppIntl();
  const { 'patient-relations': patientRelations } = useDictionaries();

  return useMemo(
    (): ColumnDescription<PrimaryPatientBaseInfo, FormatExtraDataProps>[] => [
      {
        dataField: 'clinicyMrn',
        text: formatMessage({ id: 'CORE.LABEL.CLINICY-MRN' }),
        formatter: (_, row) => (
          <a href={`/clinics/${clinicId}/patients/${row.id}`} rel="noreferrer noopener" target="_blank">
            {row.clinicyMrn}
          </a>
        ),
        style: { width: '20%' },
      },
      {
        dataField: 'fullName',
        text: formatMessage({ id: 'CORE.TABLE.FULL-NAME' }),
        formatter: (_, row, rowIndex) => (
          <>
            <span>{getPatientFullName(row, locale)}</span>
            {rowIndex === 0 && (
              <>
                <IconLock
                  className="ms-1 text-secondary cursor-pointer"
                  id="addDependentDefaultPrimaryPatient"
                  size={16}
                  strokeWidth={2.3}
                  style={{ marginTop: -2 }}
                />
                <UncontrolledTooltip target="addDependentDefaultPrimaryPatient">
                  {formatMessage({ id: 'CORE.LABEL.DEFAULT-PRIMARY-PATIENT' })}
                </UncontrolledTooltip>
              </>
            )}
          </>
        ),
        style: { width: '45%', minWidth: '200px' },
      },
      {
        dataField: 'relation',
        text: '',
        headerFormatter: () => (
          <>
            <span>{formatMessage({ id: 'CORE.LABEL.RELATION' })}</span>
            <span className="text-danger ms-1">*</span>
          </>
        ),
        formatter: (_, row, rowIndex, formatExtraData) => {
          const isOtherSelected = row.relation?.value === PatientRelation.Other;
          const onChange: EventWithDataHandler<ChangeEvent> = (_, newValue: Option<PatientRelation>) => {
            if (formatExtraData?.form && newValue.value !== PatientRelation.Other) {
              dispatch(change(formatExtraData.form, `primaryPatients.[${rowIndex}].otherRelationText`, null));
            }
          };

          return (
            <>
              <Field
                className={isOtherSelected ? 'mb-2' : undefined}
                component={SelectComponent}
                isRequired
                menuPosition="fixed"
                name={`primaryPatients[${rowIndex}].relation`}
                noMargin
                onChange={onChange}
                options={patientRelations}
                size="sm"
                validate={[required]}
              />
              {isOtherSelected && (
                <Field
                  component={InputComponent}
                  name={`primaryPatients[${rowIndex}].otherRelationText`}
                  noMargin
                  placeholder={formatMessage({ id: 'CORE.PLACEHOLDER.TYPE-OTHER' })}
                  size="sm"
                  type="text"
                  validate={[required]}
                />
              )}
            </>
          );
        },
        formatExtraData: { form },
        style: { width: '20%', minWidth: '180px' },
      },
      {
        dataField: 'actions',
        text: '',
        formatter: (_, __, rowIndex, formatExtraData) => (
          <IconButton
            tooltipMessageId="CORE.BUTTON.REMOVE"
            disabled={rowIndex === 0}
            icon={<IconTrash size={18} strokeWidth={1.8} />}
            onClick={() => formatExtraData?.removeField?.(rowIndex)}
          />
        ),
        formatExtraData: { removeField },
        headerClasses: 'text-center sticky sticky--end',
        classes: 'text-center sticky sticky--end',
      },
    ],
    [clinicId, dispatch, form, formatMessage, locale, patientRelations, removeField],
  );
};
