import { FormEvent, useCallback, useEffect, useState } from 'react';
import { IconChevronLeft, IconChevronRight, IconChevronsLeft, IconChevronsRight } from '@tabler/icons-react';
import { OnChangeValue } from 'react-select/dist/declarations/src/types';
import { Form, Input } from 'reactstrap';
import { useAppIntl } from 'app/helpers';
import { IconButton, Select } from 'app/components';
import { RefreshingBadge } from 'app/components/refreshingBadge/refreshingBadge';
import { Option } from 'app/types';
import 'app/components/stickyPagination/stickyPagination.scss';

interface Props {
  loading?: boolean;
  onPageChange?: (page: number, sizePerPage: number) => void;
  onSizePerPageChange?: (sizePerPage: number, page: number) => void;
  page: number;
  pageSizeOptions?: Option<number>[];
  refreshing?: boolean;
  sizePerPage: number;
  totalSize: number;
}

const defaultPageSizeOptions: Option<number>[] = [
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '15', value: 15 },
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

export const StickyPagination = ({
  loading,
  onPageChange,
  onSizePerPageChange,
  page,
  pageSizeOptions,
  refreshing,
  sizePerPage,
  totalSize,
}: Props) => {
  const { formatMessage } = useAppIntl();
  const [value, setValue] = useState<string>('');
  const lastPage = Math.ceil(totalSize / sizePerPage) || 1;
  const isPrevDisabled = page <= 1;
  const isNextDisabled = page >= lastPage;

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onPageChange && onPageChange(Number(value) || page, sizePerPage);
    },
    [onPageChange, page, sizePerPage, value],
  );

  const handleArrowAction = useCallback(
    (page: number) => {
      setValue(String(page));
      onPageChange && onPageChange(page, sizePerPage);
    },
    [onPageChange, sizePerPage],
  );

  const onChangePageSize = useCallback(
    (newValue: OnChangeValue<Option<number>, false>) => {
      if (newValue && onSizePerPageChange) {
        onSizePerPageChange(newValue.value, page);
      }
    },
    [onSizePerPageChange, page],
  );

  useEffect(() => {
    setValue(String(page));
  }, [page]);

  return (
    <div className="stickyPagination">
      <div className="stickyPagination__pageSize d-none d-sm-flex">
        <Select
          inputId="pageSize"
          isDisabled={loading}
          menuPlacement="top"
          name="pageSize"
          onChange={onChangePageSize}
          options={pageSizeOptions || defaultPageSizeOptions}
          placeholder={sizePerPage}
          size="sm"
          value={{ label: String(sizePerPage), value: sizePerPage }}
        />
        <span className="d-none d-md-inline ms-2">{formatMessage({ id: 'CORE.TEXT.ROWS-PER-PAGE' })}</span>
      </div>

      <div className="stickyPagination__pages">
        {/* Fetching Badge */}
        <RefreshingBadge refreshing={refreshing} wrapperStyle={{ bottom: '48px' }} />

        <IconButton
          className="stickyPagination__arrow d-none d-sm-inline-block me-1"
          disabled={isPrevDisabled || loading}
          icon={<IconChevronsLeft size={20} className="icon" />}
          onClick={() => handleArrowAction(1)}
        />

        <IconButton
          className="stickyPagination__arrow me-2"
          disabled={isPrevDisabled || loading}
          icon={<IconChevronLeft size={20} className="icon" />}
          onClick={() => handleArrowAction(page - 1)}
        />

        <span className="d-none d-md-inline me-2">{formatMessage({ id: 'CORE.TEXT.PAGE' })}</span>

        <div className="d-inline-block me-2">
          <Form onSubmit={onSubmit}>
            <Input
              bsSize="sm"
              className="stickyPagination__input"
              disabled={loading}
              max={lastPage}
              min={1}
              onChange={(e) => setValue(e.target.value)}
              pattern="[0-9]"
              placeholder={String(page)}
              step="1"
              type="number"
              value={value}
            />
          </Form>
        </div>

        <span className="me-2">{formatMessage({ id: 'CORE.TEXT.PAGE-OF' }, { limit: lastPage })}</span>

        <IconButton
          className="stickyPagination__arrow me-1"
          disabled={isNextDisabled || loading}
          icon={<IconChevronRight size={20} className="icon" />}
          onClick={() => handleArrowAction(page + 1)}
        />

        <IconButton
          className="stickyPagination__arrow d-none d-sm-inline-block"
          disabled={isNextDisabled || loading}
          icon={<IconChevronsRight size={20} className="icon" />}
          onClick={() => handleArrowAction(lastPage)}
        />
      </div>
    </div>
  );
};
