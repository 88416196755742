import { ElementType, ReactNode, useMemo } from 'react';
import { Button, Spinner, UncontrolledTooltip } from 'reactstrap';
import { v4 } from 'uuid';
import { useAppIntl } from 'app/helpers';
import styles from './iconButton.module.scss';

interface Props {
  className?: string;
  disabled?: boolean;
  download?: boolean;
  href?: string;
  icon: ReactNode;
  loaderSize?: number;
  loaderStrokeWidth?: number;
  loading?: boolean;
  onClick?: () => void;
  rel?: string;
  size?: 'sm' | 'lg';
  tag?: ElementType;
  target?: string;
  to?: string;
  tooltipMessage?: string;
  tooltipMessageId?: string;
}

export const IconButton = (props: Props) => {
  const { formatMessage } = useAppIntl();
  const loaderSize = props.loaderSize || 18;
  const loaderStrokeWidth = props.loaderStrokeWidth || 1.8;
  const targetId = useMemo<string>(() => `ib-${v4()}`, []);

  return (
    <>
      {(props.tooltipMessage || props.tooltipMessageId) && (
        <UncontrolledTooltip delay={{ show: 0, hide: 0 }} fade={false} target={targetId}>
          {props.tooltipMessage || formatMessage({ id: props.tooltipMessageId, defaultMessage: '' })}
        </UncontrolledTooltip>
      )}
      <Button
        className={props.className}
        color="icon"
        disabled={props.disabled}
        download={props.download}
        href={props.href}
        id={targetId}
        onClick={props.onClick}
        rel={props.rel}
        size={props.size}
        tag={props.tag || 'a'}
        target={props.target}
      >
        {props.loading && (
          <div className={styles.loader}>
            <Spinner style={{ width: loaderSize, height: loaderSize, borderWidth: loaderStrokeWidth }} />
          </div>
        )}
        <div style={{ opacity: props.loading ? 0 : 1 }}>{props.icon}</div>
      </Button>
    </>
  );
};
