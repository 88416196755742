import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import { RejectInstitutionBodyParams } from 'app/services/clinics/types';
import {
  MedicalInstitution,
  MedicalInstitutionRequest,
  MedicalInstitutionStatus,
  QueryParams,
  QueryResponse,
  QueryResponseWithPagination,
  RejectedMedicalInstitution,
} from 'app/types';

export const clinicsApi = createApi({
  reducerPath: 'clinicsApi',
  baseQuery: baseQueryWithReAuth,
  // TODO: Rewrite requests to RTK
  tagTypes: ['Clinic', 'RegisteredClinics', 'PendingClinics', 'CurrentRequests', 'RejectedClinics', 'DeletedClinics'],
  endpoints: (builder) => ({
    getClinic: builder.query<QueryResponse<MedicalInstitution>, string>({
      query: (id) => ({
        url: `clinics/${id}`,
      }),
      providesTags: ['Clinic'],
    }),

    getRegisteredClinics: builder.query<QueryResponseWithPagination<MedicalInstitution[]>, QueryParams | undefined>({
      query: (params) => {
        return {
          url: 'clinics',
          params: {
            ...params,
            status: params?.status
              ? params.status
              : [MedicalInstitutionStatus.Online, MedicalInstitutionStatus.Blocked, MedicalInstitutionStatus.Offline],
          },
        };
      },
      providesTags: ['RegisteredClinics'],
    }),

    getDeletedClinics: builder.query<QueryResponseWithPagination<MedicalInstitution[]>, QueryParams | undefined>({
      query: (params) => {
        return {
          url: 'clinics',
          params: {
            ...params,
            statuses: [MedicalInstitutionStatus.Deleted],
          },
        };
      },
      providesTags: ['DeletedClinics'],
    }),

    getRejectedClinics: builder.query<
      QueryResponseWithPagination<RejectedMedicalInstitution[]>,
      QueryParams | undefined
    >({
      query: (params) => ({
        url: 'rejected-clinics',
        params,
      }),
      providesTags: ['RejectedClinics'],
    }),

    getPendingClinics: builder.query<QueryResponseWithPagination<MedicalInstitution[]>, QueryParams | undefined>({
      query: (params) => {
        return {
          url: 'clinics',
          params: {
            ...params,
            statuses: [MedicalInstitutionStatus.Pending],
          },
        };
      },
      providesTags: ['PendingClinics'],
    }),

    approveClinic: builder.mutation<void, { clinicId: string }>({
      query: ({ clinicId }) => ({
        url: `clinics/${clinicId}/approve`,
        method: 'POST',
      }),
      invalidatesTags: ['PendingClinics', 'RejectedClinics'],
    }),

    rejectClinic: builder.mutation<void, { clinicId: string; body: RejectInstitutionBodyParams }>({
      query: ({ clinicId, body }) => ({
        url: `clinics/${clinicId}/reject`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PendingClinics'],
    }),

    getCurrentClinicsRequests: builder.query<
      QueryResponseWithPagination<MedicalInstitutionRequest[]>,
      QueryParams | undefined
    >({
      query: (params) => ({
        url: 'requests',
        method: 'GET',
        params,
      }),
      providesTags: ['CurrentRequests'],
    }),

    getPastClinicsRequests: builder.query<
      QueryResponseWithPagination<MedicalInstitutionRequest[]>,
      QueryParams | undefined
    >({
      query: (params) => ({
        url: 'requests/history',
        method: 'GET',
        params,
      }),
    }),

    getPatientClinics: builder.query<
      QueryResponseWithPagination<MedicalInstitution[]>,
      { patientId: string; params: QueryParams | undefined }
    >({
      query: ({ patientId, params }) => ({
        url: `patients/${patientId}/global-booking-statistic/clinics`,
        method: 'GET',
        params,
      }),
    }),
    changeClinicStatus: builder.mutation<void, { clinicId: string; body: any }>({
      query: ({ clinicId, body }) => ({
        url: `/clinics/${clinicId}`,
        method: 'PUT',
        body,
        providesTags: ['Clinic'],
      }),
    }),
  }),
});

export const {
  useGetClinicQuery,
  useApproveClinicMutation,
  useGetCurrentClinicsRequestsQuery,
  useGetDeletedClinicsQuery,
  useGetPastClinicsRequestsQuery,
  useGetPatientClinicsQuery,
  useGetPendingClinicsQuery,
  useGetRegisteredClinicsQuery,
  useGetRejectedClinicsQuery,
  useRejectClinicMutation,
  useChangeClinicStatusMutation,
} = clinicsApi;
