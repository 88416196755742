import { put, takeEvery } from 'redux-saga/effects';
import {
  ADD_BRANCH_WORKING_HOURS_SUCCESS,
  DELETE_BRANCH_SUCCESS,
  EDIT_BRANCH_IMAGES_SUCCESS,
  EDIT_BRANCH_SUCCESS,
  REMOVE_BRANCH_WORKING_HOURS_SUCCESS,
} from 'app/redux/branch/branch.types';
import { branchesApi } from 'app/services/branches/branches';
import { fetchBranch, fetchBranchWorkingHours } from 'app/redux/branch/branch.actions';
import { toggleBranchImagesModal, toggleModal } from 'app/redux/modals/modals.actions';

export function* afterDeleteBranchSuccess() {
  yield put(branchesApi.util.invalidateTags(['Branches']));
}

export function* afterEditBranchSuccess({ meta }) {
  yield put(fetchBranch(meta.branchId));
}

export function* afterEditBranchImagesSuccess({ meta }) {
  yield put(fetchBranch(meta.branchId));
  yield put(toggleBranchImagesModal());
}

export function* afterAddWorkingHoursSuccess({ meta }) {
  yield put(toggleModal('addBranchWorkingHours', false));
  yield put(fetchBranchWorkingHours(meta.branchId));
}

export function* afterRemoveWorkingHoursSuccess({ meta }) {
  yield put(fetchBranchWorkingHours(meta.branchId));
}

function* branchesSaga() {
  yield takeEvery(DELETE_BRANCH_SUCCESS, afterDeleteBranchSuccess);
  yield takeEvery(EDIT_BRANCH_SUCCESS, afterEditBranchSuccess);
  yield takeEvery(EDIT_BRANCH_IMAGES_SUCCESS, afterEditBranchImagesSuccess);
  yield takeEvery(ADD_BRANCH_WORKING_HOURS_SUCCESS, afterAddWorkingHoursSuccess);
  yield takeEvery(REMOVE_BRANCH_WORKING_HOURS_SUCCESS, afterRemoveWorkingHoursSuccess);
}

export default branchesSaga;
