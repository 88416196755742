import { ICD10Code } from 'app/types/Assessment';
import { InitialAssessmentAppointmentType } from 'app/types/InitialAssessment';
import { Option } from 'app/types/Option';
import {
  GovernmentIssuedIdType,
  PatientEmergencyContactRelation,
  PatientGender,
  PatientStatus,
} from 'app/types/Patient';
import { RemoteFile } from 'app/types/RemoteFile';
import { TreatmentPlanSpecialtyNote } from 'app/features/treatmentPlan/types';

export interface TreatmentPlanData {
  initialAssessmentId?: string;
  appointmentId: string;
  generalTreatmentInformation: TreatmentInformation;
  previousVisitsLogSection: PreviousVisitsLogSection;
}

export interface TreatmentInformation {
  clinicInformation: ClinicInformation;
  patientInformation: PatientInformation;
  appointmentInformation: AppointmentInformation;
  paymentSource: TreatmentPlanPaymentSource;
  patientVitals: PatientVitals;
  background: PatientBackground;
  medicalHistory: MedicalHistory;
  ordersSection: OrdersSection;
  attachmentsSection: AttachmentsInformation;
  prescriptionsSection: PrescriptionsInformation;
  readOnly: boolean;
  reportedAllergies: ReportedAllergyInformation;
  initialAssessmentComment: InitialAssessmentComment;
  specialityNoteSection: SpecialityNoteSection;
  emergencyContactSection: EmergencyContactInformation;
  previousSeenDoctorsSection: PreviousSeenDoctorsInformation;
}

export interface ClinicInformation {
  clinicBranchAddress: Option;
  clinicBranchId: string;
  clinicBranchNames: Option;
  clinicBranchPhoneNumber: string;
  clinicId: string;
  clinicLogo: RemoteFile;
  clinicNames: Option;
  doctorId: string;
  branchHasDifferentInfoThanMI: boolean;
  clinicBranchLogo: RemoteFile | null;
}

export interface PatientInformation {
  age?: number;
  clinicyMrn?: string;
  dateOfBirth?: string;
  familyName?: string;
  fatherName: string;
  firstName: string;
  fullNameEnglish: string;
  fullNameArabic: string;
  gender?: Option<PatientGender>;
  globalPatientId: string;
  governmentIssuedId?: string;
  governmentIssuedIdType?: Option<GovernmentIssuedIdType>;
  grandfatherName?: string;
  internalFileNumber?: string;
  invoicingGovernmentIssuedId?: string;
  name: string;
  nationality: string;
  patientId: string;
  saudiCitizenship: boolean;
  status: Option<PatientStatus>;
}

export interface ReportedAllergyInformation {
  allergies: ReportedAllergy[];
  clinicId: string;
  patientId: string;
  readOnly: boolean;
}

export interface AppointmentInformation {
  appointmentType?: Option<InitialAssessmentAppointmentType>;
  doctorId: string;
  doctorName: string;
  doctorTitle: string;
  end: string;
  hasMergePatientsBadge: boolean;
  id: string;
  lastVisitedAt: string | null;
  referralId: string;
  serviceName: string;
  specialityId: string;
  specialityName: string;
  start: string;
  subSpecialityName: string;
  visitStatus: string | null;
}

export enum TreatmentPlanPaymentMethodType {
  None = '0',
  Cash = '1',
  Insurance = '2',
}

export interface TreatmentPlanPaymentSource {
  paymentSource: Option<TreatmentPlanPaymentMethodType> | null;
  insurance: PaymentSourceInsuranceOption | null;
}

export interface PatientVitals {
  temperature: number | null;
  pulse: number | null;
  bloodPressureFirstPart: string | null;
  bloodPressureSecondPart: string | null;
  respiratoryRate: number | null;
  oxygenSaturation: number | null;
  bloodGlucose: number | null;
  height: number | null;
  weight: number | null;
  bmi: number | null;
  pain: number | null;
  headCircumference: number | null;
}

export interface PatientBackground {
  durationOfIllnessInDays: number | null;
  visitReason: string | null;
  patientMedicalIllness: boolean;
  familyHistoryIllness: boolean;
  previousSurgeries: boolean;
  how: string | null;
  when: string | null;
  where: string | null;
  illnessTypes: BackgroundIllnessTypes;
  necessaryQuestions: Option[];
}

export interface BackgroundIllnessTypes {
  rta: boolean | null;
  chronicDiseases: boolean | null;
  vaccination: boolean | null;
  checkUp: boolean | null;
  psychiatric: boolean | null;
  infertility: boolean | null;
  medications: boolean | null;
  allergies: boolean | null;
  smokingStatus: boolean | null;
  pregnancy: boolean | null;
  indicateLmp: string | null;
  other: boolean | null;
  workRelatedAccident: boolean | null;
  sportRelated: boolean | null;
  orthodonticOrAesthetics: boolean | null;
  congenitalOrDevelopmental: boolean | null;
  cleaning: boolean | null;
  na: boolean | null;
  otherMessage: string | null;
  regularDentalTreatment: boolean | null;
}

export interface MedicalHistory {
  patientId: string;
  patientFamilyMedicalIllnesses: PatientFamilyMedicalIllness[];
  patientMedicalIllnesses: PatientMedicalIllness[];
  previousSurgeries: PreviousSurgery[];
  updateActionsEnabledForUser: boolean;
}

export interface OrdersSection {
  clinicId: string;
  orders: PreviousOrder[];
  patientId: string;
}

export interface PreviousOrder {
  hasMergePatientsBadge: boolean;
  id: string;
  number: string;
  timeOfCreation: string;
}

export interface PreviouslySeenDoctor {
  appointmentStart: string;
  doctorId: string;
  doctorSpecialityName: string;
  hasMergePatientsBadge: boolean;
  id: string;
}

export interface PreviousSeenDoctorsInformation {
  previousSeenDoctors: PreviouslySeenDoctor[];
}

export interface AttachmentsInformation {
  attachments: Attachment[];
  clinicId: string;
  patientId: string;
  readOnly: boolean;
}

export interface Attachment {
  attachmentName: string;
  addedAt: string;
  id: string;
  hasMergePatientsBadge: boolean;
}

export interface PrescriptionsInformation {
  doctorId: string;
  patientId: string;
  prescriptions: PrescribedDrug[];
  readOnly: boolean;
}

export interface PrescribedDrug {
  id: string;
  drug: Option;
  prescribedAt: string;
  hasMergePatientsBadge: boolean;
}

export interface ReportedAllergy {
  addedAt: string;
  allergyCause: Option;
  hasMergePatientsBadge: boolean;
  id: string;
  patientId: string;
}

export interface PatientFamilyMedicalIllness {
  id: string;
  medicalIllness: Option;
  deleteActionEnabledForUser: boolean;
  addedAt: string;
  addedBy: Option;
  hasMergePatientsBadge: boolean;
}

export interface PatientMedicalIllness {
  id: string;
  medicalIllness: Option;
  addedAt: string;
  addedBy: Option;
  deleteActionEnabledForUser: boolean;
  sinceMonth: number | null;
  sincePeriod: Option | null;
  sinceYear: number | null;
  hasMergePatientsBadge: boolean;
}

export interface PatientMedicalIllnessTable extends PatientMedicalIllness {
  toPrint?: boolean;
}

export interface PreviousSurgery {
  addedAt: string;
  addedBy: Option;
  deleteActionEnabledForUser: boolean;
  hasMergePatientsBadge: boolean;
  id: string;
  sinceMonth: number | null;
  sincePeriod: Option | null;
  sinceYear: number | null;
  surgery: Option;
}

export interface PreviousSurgeryTable extends PreviousSurgery {
  toPrint?: boolean;
}

export interface InitialAssessmentComment {
  initialAssessmentCommentExists: boolean;
  initialAssessmentComment: string | null;
}

export interface GeneralVisitsLogInfo {
  createDoctorAssessmentActionsEnabled: boolean;
  currentDoctorAssessmentId: string;
  readOnly: boolean;
}

export interface PreviousVisitsLogSection extends GeneralVisitsLogInfo {
  previousVisits: PreviousVisit[];
}

export interface PreviousVisit {
  appointmentDate: string;
  appointmentId: string;
  canAssessmentChainBeContinued: boolean;
  diagnoseDescription: DiagnosesDescription[];
  doctor: Option;
  editActionsAreEnabled: boolean;
  hasMergePatientsBadge: boolean;
  id: string;
  mainDoctorAssessmentId: string;
  previousAppointmentDate: string | null;
  previousDoctorAssessmentId: string | null;
  speciality: Option;
  status: PreviousVisitStatus;
  subSpeciality: Option;
}

export enum PreviousVisitStatus {
  Uncompleted = 0,
  Completed = 1,
}

interface PaymentSourceInsuranceOption extends Option {
  details: { number: string };
}

export interface MedicalHistoryCategory {
  numberOfItems: number;
  title: string;
}

export interface SpecialityNoteSection {
  addActionEnabledForUser: boolean;
  clinicId: string;
  patientId: string;
  readOnly: boolean;
  specialityNotes: TreatmentPlanSpecialtyNote[];
  updateActionEnabledForUser: boolean;
}

export interface DiagnosesDescription {
  text?: string;
  icd10Codes: ICD10Code[];
}

export interface TreatmentPlanEmergencyContact {
  id: string;
  speciality: Option;
  contactName: Option;
  relation: Option<PatientEmergencyContactRelation>;
  hasMergePatientsBadge: boolean;
  otherRelation: string;
  contactPhoneNumber: string;
  actionsAreEnabledForUser: boolean;
}

export interface EmergencyContactInformation {
  updateActionEnabledForUser: boolean;
  addActionEnabledForUser: boolean;
  patientId: string;
  clinicId: string;
  readOnly: boolean;
  emergencyContacts: TreatmentPlanEmergencyContact[];
}
