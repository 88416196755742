export const branchesTab = {
  active: 'active',
  deleted: 'deleted',
} as const;
export const doctorLogsTabs = {
  relatedToPractitioner: 'related-to-logs',
  practitionerLogs: 'practitioner-logs',
} as const;
export const clinicsPathCore = '/clinics';
export const clinicBranchesPath = `${clinicsPathCore}/:clinicId/branches/:tab(${Object.values(branchesTab).join('|')})`;
export const doctorsLogsPathCore = '/clinics/:clinicId/doctor';
export const doctorsLogsPath = `${doctorsLogsPathCore}/:doctorId/logs/:tab(${Object.values(doctorLogsTabs).join('|')})`;
