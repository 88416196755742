import { useAppIntl } from 'app/helpers';
import logo from 'assets/images/logo/clinicy-logo.png';

export const Maintenance = () => {
  const { formatMessage } = useAppIntl();

  return (
    <div className="text-center px-2 py-6">
      <img alt="logo" className="mb-3" style={{ width: 130, height: 'auto' }} src={logo} />
      <h2 className="mb-0">{formatMessage({ id: 'CORE.TEXT.WE-ARE-UNDER-MAINTENANCE' })}</h2>
      <p>{formatMessage({ id: 'CORE.TEXT.WILL-BE-BACK-SOON' })}</p>
    </div>
  );
};
