import { ModalsState } from 'app/redux/modals/modals.reducer';
import * as types from 'app/redux/modals/modals.types';

export const toggleModal = (modalName: keyof ModalsState, status?: boolean) => ({
  type: types.TOGGLE_MODAL,
  payload: {
    modalName,
    status,
  },
});

export const toggleChangePasswordModal = (options?: { formDescription: string }) => ({
  type: types.TOGGLE_CHANGE_PASSWORD_MODAL,
  payload: options,
});

export const toggleConfirmationModal = (onConfirm: Function, translationKey: string) => ({
  type: types.TOGGLE_CONFIRMATION_MODAL,
  payload: { onConfirm, translationKey },
});

export const toggleAddPatientToClinic = (patientId: string, clinicId: string, mobileNumber: string) => ({
  type: types.TOGGLE_ADD_PATIENT_TO_CLINIC_MODAL,
  payload: { patientId, clinicId, mobileNumber },
});

// TODO: Important! We should replace all the actions below with toggleModal

export const toggleAddBranchModal = () => ({
  type: types.TOGGLE_ADD_BRANCH_MODAL,
});

export const toggleEditClinicModal = () => ({
  type: types.TOGGLE_EDIT_CLINIC_MODAL,
});

export const toggleEditClinicRequestModal = () => ({
  type: types.TOGGLE_EDIT_CLINIC_REQUEST_MODAL,
});

export const toggleEditBranchModal = () => ({
  type: types.TOGGLE_EDIT_BRANCH_MODAL,
});

export const toggleEditBranchRequestModal = () => ({
  type: types.TOGGLE_EDIT_BRANCH_REQUEST_MODAL,
});

export const toggleEditSystemOwnerModal = () => ({
  type: types.TOGGLE_EDIT_SYSTEMOWNER_MODAL,
});

export const toggleAddDoctorModal = () => ({
  type: types.TOGGLE_ADD_DOCTOR_MODAL,
});

export const toggleAddDoctorBusyTimeModal = () => ({
  type: types.TOGGLE_ADD_DOCTOR_BUSY_TIME_MODAL,
});

export const toggleBranchImagesModal = () => ({
  type: types.TOGGLE_BRANCH_IMAGES_MODAL,
});

export const toggleAddPatientModal = () => ({
  type: types.TOGGLE_ADD_PATIENT_MODAL,
});

export const toggleAddBranchWorkingHoursModal = () => ({
  type: types.TOGGLE_ADD_BRANCH_WORKING_HOURS_MODAL,
});

export const toggleExportToXLSModal = () => ({
  type: types.TOGGLE_EXPORT_TO_XLS_MODAL,
});

export const toggleEditProfileModal = () => ({
  type: types.TOGGLE_EDIT_PROFILE_MODAL,
});

export const toggleDeletedDoctorModal = () => ({
  type: types.TOGGLE_DELETED_DOCTOR_MODAL,
});

export const toggleRejectedClinicInfoModal = () => ({
  type: types.TOGGLE_REJECTED_CLINIC_INFO,
});

export const toggleAddUserRole = () => ({
  type: types.TOGGLE_ADD_USER_ROLE_MODAL,
});

export const toggleEditUserRole = () => ({
  type: types.TOGGLE_EDIT_USER_ROLE_MODAL,
});

export const toggleCancelPendingClinic = () => ({
  type: types.TOGGLE_CANCEL_PENDING_CLINIC,
});

export const toggleRejectPendingClinic = () => ({
  type: types.TOGGLE_REJECT_PENDING_CLINIC,
});

export const toggleAddProductModal = () => ({
  type: types.TOGGLE_ADD_PRODUCT,
});

export const toggleBranchesModal = () => ({
  type: types.TOGGLE_BRANCHES_MODAL,
});

export const toggleEditProductModal = () => ({
  type: types.TOGGLE_EDIT_PRODUCT_MODAL,
});

export const toggleDeletedBranchModal = () => ({
  type: types.TOGGLE_DELETED_BRANCH_MODAL,
});

export const toggleDoctorWorkingHoursModal = () => ({
  type: types.TOGGLE_DOCTOR_WORKING_HOURS_MODAL,
});

export const toggleAddDraftItemModal = () => ({
  type: types.TOGGLE_ADD_DRAFT_ITEM_MODAL,
});

export const toggleEditDraftItemModal = () => ({
  type: types.TOGGLE_EDIT_DRAFT_ITEM_MODAL,
});

export const toggleAddInvoiceItemModal = () => ({
  type: types.TOGGLE_ADD_INVOICE_ITEM_MODAL,
});

export const toggleEditInvoiceItemModal = () => ({
  type: types.TOGGLE_EDIT_INVOICE_ITEM_MODAL,
});
