import { useMemo } from 'react';
import { useDictionaries } from 'app/helpers';
import { PreferredLanguage } from 'app/shared';
import { PatientFormData, PatientFormField } from 'app/features/patient/patientForm/types';
import { PreSearchDependentResult } from 'app/features/patient/preSearchClinicPatientModal/form/types';

export const useInitialValuesDependent = (primaryPatientData: PreSearchDependentResult) => {
  const { 'notification-languages': languageOptions } = useDictionaries();

  return useMemo(
    (): Partial<PatientFormData> => ({
      [PatientFormField.patientMobileNumbers]: [{ isDefault: true, phoneNumber: primaryPatientData.phoneNumber }],
      [PatientFormField.primaryPatients]: [primaryPatientData.primaryPatientData],
      [PatientFormField.preferredLanguage]: languageOptions?.find((lang) => lang.value === PreferredLanguage.Arabic),
    }),
    [primaryPatientData, languageOptions],
  );
};
