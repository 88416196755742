import { useMemo } from 'react';
import { useDictionaries } from 'app/helpers';
import { PreferredLanguage } from 'app/shared';
import { PatientFormData, PatientFormField } from 'app/features/patient/patientForm/types';

export const useInitialValues = (initialPhoneNumber: string) => {
  const { 'notification-languages': languageOptions } = useDictionaries();

  return useMemo(
    (): Partial<PatientFormData> => ({
      [PatientFormField.patientMobileNumbers]: [{ isDefault: true, phoneNumber: initialPhoneNumber }],
      [PatientFormField.preferredLanguage]: languageOptions?.find((lang) => lang.value === PreferredLanguage.Arabic),
    }),
    [initialPhoneNumber, languageOptions],
  );
};
