import { AnyAction } from 'redux';
import { generateModalState } from 'app/redux/modals/helpers';
import * as types from 'app/redux/modals/modals.types';
import { ModalState } from 'app/redux/modals/types';

export interface ModalsState {
  actionTaker: ModalState;
  addActionContractor: ModalState;
  addAllergy: ModalState;
  addAppointment: ModalState;
  addAssessmentOrder: ModalState;
  addBranch: ModalState;
  addBranchBillingItem: ModalState;
  addBranchMedication: ModalState;
  addBranchWorkingHours: ModalState;
  addClinic: ModalState;
  addContractor: ModalState;
  addContractorAttachment: ModalState;
  addDoctor: ModalState;
  addDoctorBusyTime: ModalState;
  addDoctorCustomWorkingHours: ModalState;
  addDoctorWorkingHours: ModalState;
  addDraftItem: ModalState;
  addExclusion: ModalState;
  addMedicalLibrariesAllergy: ModalState;
  addMedicalLibrariesDrug: ModalState;
  addMedicalLibrariesFamilyIllness: ModalState;
  addMedicalLibrariesPatientIllness: ModalState;
  addMedicalLibrariesSurgery: ModalState;
  addGeneralBillingItem: ModalState;
  addGeneralGroupItem: ModalState;
  addGuardianToDependent: ModalState;
  addInsuranceAttachment: ModalState;
  addInsuranceBillingItem: ModalState;
  addInsuranceDiagnosis: ModalState;
  addInsuranceMedicals: ModalState;
  addInvoiceItem: ModalState;
  addMassBillingItemsContracts: ModalState;
  addMassBillingMedicationsContracts: ModalState;
  addMassBillingPackagesContracts: ModalState;
  addMassContracts: ModalState;
  addOrder: ModalState;
  addOrderComment: ModalState;
  addOrderReport: ModalState;
  addPatient: ModalState;
  addPatientDependent: ModalState;
  addPerformerBillingItem: ModalState;
  addPolicy: ModalState;
  addPolicyAttachment: ModalState;
  addPolicyClass: ModalState;
  addPolicyClassAttachment: ModalState;
  addPrescriptionModal: ModalState;
  addProduct: ModalState;
  addReferralApprovalBillingItem: ModalState;
  addUserRole: ModalState;
  addWaitingAppointment: ModalState;
  appointmentConfirmation: ModalState;
  appointmentTicket: ModalState;
  assessmentInformation: ModalState;
  assessmentModal: ModalState;
  assignMedicationsToPerformers: ModalState;
  billingItemsListMassAdding: ModalState;
  blockPatient: ModalState;
  bookAppointment: ModalState;
  bookingWizard: ModalState;
  branchBillingItemsMassAdding: ModalState;
  branchImages: ModalState;
  branchMedicationsMassAdding: ModalState;
  branchPackage: ModalState;
  branchPackageMassAdding: ModalState;
  branches: ModalState;
  cancelPendingClinic: ModalState;
  changeAppointmentPatient: ModalState;
  changeContractRetrieve: ModalState;
  changeContractorRetrieve: ModalState;
  changeOrderStatus: ModalState;
  checkInProcess: ModalState;
  confirmSendVerificationCode: ModalState;
  createInsuranceReferralRequest: ModalState;
  createInsuranceRequest: ModalState;
  creditNote: ModalState;
  creditNotePackageActions: ModalState;
  deletedBranch: ModalState;
  deletedDoctor: ModalState;
  discardReferral: ModalState;
  doctorWorkingHours: ModalState;
  doneOrderStatus: ModalState;
  editBillingItemAliasName: ModalState;
  editBillingItemPrice: ModalState;
  editAllergy: ModalState;
  editAppointment: ModalState;
  editBranch: ModalState;
  editBranchBillingItem: ModalState;
  editBranchMedication: ModalState;
  editBranchRequest: ModalState;
  editClinic: ModalState;
  editClinicPending: ModalState;
  editClinicRequest: ModalState;
  editContractor: ModalState;
  editContract: ModalState;
  editDoctor: ModalState;
  editDoctorCustomWorkingHours: ModalState;
  editDoctorWorkingHours: ModalState;
  editDraftItem: ModalState;
  editGeneralBillingItem: ModalState;
  editGeneralGroupItem: ModalState;
  editInsuranceRequest: ModalState;
  editInvoiceItem: ModalState;
  editMedicalLibrariesAllergy: ModalState;
  editMedicalLibrariesDrug: ModalState;
  editMedicalLibrariesFamilyIllness: ModalState;
  editMedicalLibrariesPatientIllness: ModalState;
  editMedicalLibrariesSurgery: ModalState;
  editOrder: ModalState;
  editPatient: ModalState;
  editPatientDependent: ModalState;
  editPatientNumber: ModalState;
  editPerformerBillingItem: ModalState;
  editPolicy: ModalState;
  editPolicyClass: ModalState;
  editPrescription: ModalState;
  editProduct: ModalState;
  editProfile: ModalState;
  editClinicStatus: ModalState;
  editBranchStatus: ModalState;
  editSystemOwner: ModalState;
  editUserRole: ModalState;
  editWaitingAppointment: ModalState;
  emergencyContacts: ModalState;
  exportToXLS: ModalState;
  fullEditAppointment: ModalState;
  generalBillingItemGroup: ModalState;
  generateTemporaryPassword: ModalState;
  importBranchBillingItems: ModalState;
  importMedicationToInsurance: ModalState;
  importPackageToInsurance: ModalState;
  importPolicies: ModalState;
  insuranceApprovalMoreInfo: ModalState;
  insuranceApprovalsActionsLog: ModalState;
  insuranceBillingItemsContractLog: ModalState;
  insuranceBillingItemsInclusionsLog: ModalState;
  insuranceContractPolicyActionsLog: ModalState;
  insuranceContractorPolicyActionsLog: ModalState;
  insurancePolicySetUpActionsLog: ModalState;
  insuranceClassSetUpActionsLog: ModalState;
  insuranceReferralMoreInfo: ModalState;
  insuranceRemovePolicySetUpActionsLog: ModalState;
  invoiceHistory: ModalState;
  invoicesBillNote: ModalState;
  invoicesRequestItems: ModalState;
  invoicesRequestOrder: ModalState;
  invoicingProcess: ModalState;
  invoicingProcessInsuranceDetails: ModalState;
  itemPrice: ModalState;
  manageAppointments: ModalState;
  manageEmergencyContact: ModalState;
  manageSpecialtyNote: ModalState;
  manageWaitingAppointments: ModalState;
  medicalAttachment: ModalState;
  medicalHistory: ModalState;
  mergePatientProfiles: ModalState;
  moveBackReferral: ModalState;
  noteBillingItems: ModalState;
  orderDetails: ModalState;
  patientEligibility: ModalState;
  patientMergeChooser: ModalState;
  patientMergeInfo: ModalState;
  patientVerification: ModalState;
  pendingClinicReferenceNumber: ModalState;
  performerMedicationsMassAdding: ModalState;
  practitionerPackage: ModalState;
  practitionerPackageMassAdding: ModalState;
  preSearchClinicPatient: ModalState;
  preSearchRegularPatient: ModalState;
  productMassAdding: ModalState;
  promoteWaitingAppointment: ModalState;
  referPatient: ModalState;
  referralDetails: ModalState;
  rejectPendingClinic: ModalState;
  rejectedClinicInfo: ModalState;
  requestForApproval: ModalState;
  specialtyNote: ModalState;
  specialtyNoteDetails: ModalState;
  takeAction: ModalState;
  ticketNumber: ModalState;
  unblockPatient: ModalState;
  viewAttachment: ModalState;
  viewBranchBillingItem: ModalState;
  viewBranchMedication: ModalState;
  viewBranchPackages: ModalState;
  viewContractAttachments: ModalState;
  viewContractorAttachments: ModalState;
  viewPatientAllergy: ModalState;
  viewPatientPrescription: ModalState;
  viewPerformerBillingItem: ModalState;
  viewPolicyAttachments: ModalState;
  viewPolicyClassAttachments: ModalState;
  viewPractitionerMedication: ModalState;

  // Custom modals
  addPatientToClinic: ModalState & {
    patientId: string;
    clinicId: string;
    mobileNumber: string;
  };
  changePassword: ModalState;
  confirmation: ModalState & {
    isOpen: boolean;
    translationKey: string | null;
    onConfirm: Function | null;
  };
}

export const initialState: ModalsState = {
  actionTaker: generateModalState(),
  addActionContractor: generateModalState(),
  addAllergy: generateModalState(),
  addAppointment: generateModalState(),
  addAssessmentOrder: generateModalState(),
  addBranch: generateModalState(),
  addBranchBillingItem: generateModalState(),
  addBranchMedication: generateModalState(),
  addBranchWorkingHours: generateModalState(),
  addClinic: generateModalState(),
  addContractor: generateModalState(),
  addContractorAttachment: generateModalState(),
  addDoctor: generateModalState(),
  addDoctorBusyTime: generateModalState(),
  addDoctorCustomWorkingHours: generateModalState(),
  addDoctorWorkingHours: generateModalState(),
  addDraftItem: generateModalState(),
  addExclusion: generateModalState(),
  addGeneralBillingItem: generateModalState(),
  addGeneralGroupItem: generateModalState(),
  addGuardianToDependent: generateModalState(),
  addInsuranceAttachment: generateModalState(),
  addInsuranceBillingItem: generateModalState(),
  addInsuranceDiagnosis: generateModalState(),
  addInsuranceMedicals: generateModalState(),
  addInvoiceItem: generateModalState(),
  addMassBillingItemsContracts: generateModalState(),
  addMassBillingMedicationsContracts: generateModalState(),
  addMassBillingPackagesContracts: generateModalState(),
  addMassContracts: generateModalState(),
  addMedicalLibrariesAllergy: generateModalState(),
  addMedicalLibrariesDrug: generateModalState(),
  addMedicalLibrariesFamilyIllness: generateModalState(),
  addMedicalLibrariesPatientIllness: generateModalState(),
  addMedicalLibrariesSurgery: generateModalState(),
  addOrder: generateModalState(),
  addOrderComment: generateModalState(),
  addOrderReport: generateModalState(),
  addPatient: generateModalState(),
  addPatientDependent: generateModalState(),
  addPerformerBillingItem: generateModalState(),
  addPolicy: generateModalState(),
  addPolicyAttachment: generateModalState(),
  addPolicyClass: generateModalState(),
  addPolicyClassAttachment: generateModalState(),
  addPrescriptionModal: generateModalState(),
  addProduct: generateModalState(),
  addReferralApprovalBillingItem: generateModalState(),
  addUserRole: generateModalState(),
  addWaitingAppointment: generateModalState(),
  appointmentConfirmation: generateModalState(),
  appointmentTicket: generateModalState(),
  assessmentInformation: generateModalState(),
  assessmentModal: generateModalState(),
  assignMedicationsToPerformers: generateModalState(),
  billingItemsListMassAdding: generateModalState(),
  blockPatient: generateModalState(),
  bookAppointment: generateModalState(),
  bookingWizard: generateModalState(),
  branchBillingItemsMassAdding: generateModalState(),
  branchImages: generateModalState(),
  branchMedicationsMassAdding: generateModalState(),
  branchPackage: generateModalState(),
  branchPackageMassAdding: generateModalState(),
  branches: generateModalState(),
  cancelPendingClinic: generateModalState(),
  changeAppointmentPatient: generateModalState(),
  changeContractRetrieve: generateModalState(),
  changeContractorRetrieve: generateModalState(),
  changeOrderStatus: generateModalState(),
  checkInProcess: generateModalState(),
  confirmSendVerificationCode: generateModalState(),
  createInsuranceReferralRequest: generateModalState(),
  createInsuranceRequest: generateModalState(),
  creditNote: generateModalState(),
  creditNotePackageActions: generateModalState(),
  deletedBranch: generateModalState(),
  deletedDoctor: generateModalState(),
  discardReferral: generateModalState(),
  doctorWorkingHours: generateModalState(),
  doneOrderStatus: generateModalState(),
  editBillingItemAliasName: generateModalState(),
  editBillingItemPrice: generateModalState(),
  editAllergy: generateModalState(),
  editAppointment: generateModalState(),
  editBranch: generateModalState(),
  editBranchBillingItem: generateModalState(),
  editBranchMedication: generateModalState(),
  editBranchRequest: generateModalState(),
  editClinic: generateModalState(),
  editClinicPending: generateModalState(),
  editClinicRequest: generateModalState(),
  editContractor: generateModalState(),
  editContract: generateModalState(),
  editDoctor: generateModalState(),
  editDoctorCustomWorkingHours: generateModalState(),
  editDoctorWorkingHours: generateModalState(),
  editDraftItem: generateModalState(),
  editGeneralBillingItem: generateModalState(),
  editGeneralGroupItem: generateModalState(),
  editInsuranceRequest: generateModalState(),
  editInvoiceItem: generateModalState(),
  editMedicalLibrariesAllergy: generateModalState(),
  editMedicalLibrariesDrug: generateModalState(),
  editMedicalLibrariesFamilyIllness: generateModalState(),
  editMedicalLibrariesPatientIllness: generateModalState(),
  editMedicalLibrariesSurgery: generateModalState(),
  editOrder: generateModalState(),
  editPatient: generateModalState(),
  editPatientDependent: generateModalState(),
  editPatientNumber: generateModalState(),
  editPerformerBillingItem: generateModalState(),
  editPolicy: generateModalState(),
  editPolicyClass: generateModalState(),
  editPrescription: generateModalState(),
  editProduct: generateModalState(),
  editProfile: generateModalState(),
  editClinicStatus: generateModalState(),
  editBranchStatus: generateModalState(),
  editSystemOwner: generateModalState(),
  editUserRole: generateModalState(),
  editWaitingAppointment: generateModalState(),
  emergencyContacts: generateModalState(),
  exportToXLS: generateModalState(),
  fullEditAppointment: generateModalState(),
  generalBillingItemGroup: generateModalState(),
  generateTemporaryPassword: generateModalState(),
  importBranchBillingItems: generateModalState(),
  importMedicationToInsurance: generateModalState(),
  importPackageToInsurance: generateModalState(),
  importPolicies: generateModalState(),
  insuranceApprovalMoreInfo: generateModalState(),
  insuranceApprovalsActionsLog: generateModalState(),
  insuranceBillingItemsContractLog: generateModalState(),
  insuranceBillingItemsInclusionsLog: generateModalState(),
  insuranceContractPolicyActionsLog: generateModalState(),
  insuranceContractorPolicyActionsLog: generateModalState(),
  insurancePolicySetUpActionsLog: generateModalState(),
  insuranceClassSetUpActionsLog: generateModalState(),
  insuranceReferralMoreInfo: generateModalState(),
  insuranceRemovePolicySetUpActionsLog: generateModalState(),
  invoiceHistory: generateModalState(),
  invoicesBillNote: generateModalState(),
  invoicesRequestItems: generateModalState(),
  invoicesRequestOrder: generateModalState(),
  invoicingProcess: generateModalState(),
  invoicingProcessInsuranceDetails: generateModalState(),
  itemPrice: generateModalState(),
  manageAppointments: generateModalState(),
  manageEmergencyContact: generateModalState(),
  manageSpecialtyNote: generateModalState(),
  manageWaitingAppointments: generateModalState(),
  medicalAttachment: generateModalState(),
  medicalHistory: generateModalState(),
  mergePatientProfiles: generateModalState(),
  moveBackReferral: generateModalState(),
  noteBillingItems: generateModalState(),
  orderDetails: generateModalState(),
  patientEligibility: generateModalState(),
  patientMergeChooser: generateModalState(),
  patientMergeInfo: generateModalState(),
  patientVerification: generateModalState(),
  pendingClinicReferenceNumber: generateModalState(),
  performerMedicationsMassAdding: generateModalState(),
  practitionerPackage: generateModalState(),
  practitionerPackageMassAdding: generateModalState(),
  preSearchClinicPatient: generateModalState(),
  preSearchRegularPatient: generateModalState(),
  productMassAdding: generateModalState(),
  promoteWaitingAppointment: generateModalState(),
  referPatient: generateModalState(),
  referralDetails: generateModalState(),
  rejectPendingClinic: generateModalState(),
  rejectedClinicInfo: generateModalState(),
  requestForApproval: generateModalState(),
  specialtyNote: generateModalState(),
  specialtyNoteDetails: generateModalState(),
  takeAction: generateModalState(),
  ticketNumber: generateModalState(),
  unblockPatient: generateModalState(),
  viewAttachment: generateModalState(),
  viewBranchBillingItem: generateModalState(),
  viewBranchMedication: generateModalState(),
  viewBranchPackages: generateModalState(),
  viewContractAttachments: generateModalState(),
  viewContractorAttachments: generateModalState(),
  viewPatientAllergy: generateModalState(),
  viewPatientPrescription: generateModalState(),
  viewPerformerBillingItem: generateModalState(),
  viewPolicyAttachments: generateModalState(),
  viewPolicyClassAttachments: generateModalState(),
  viewPractitionerMedication: generateModalState(),

  // Custom modals
  addPatientToClinic: {
    isOpen: false,
    clinicId: '',
    patientId: '',
    mobileNumber: '',
  },
  changePassword: generateModalState({
    isOpen: false,
    formDescription: null,
  }),
  confirmation: {
    isOpen: false,
    translationKey: null,
    onConfirm: null,
  },
};

export const modalsReducer = (state = initialState, action: AnyAction): ModalsState => {
  switch (action.type) {
    case types.TOGGLE_MODAL:
      const {
        modalName,
        status,
      }: {
        modalName: keyof ModalsState;
        status?: boolean;
      } = action.payload;

      return {
        ...state,
        [modalName]: {
          ...state[modalName],
          isOpen: status === undefined ? !state[modalName].isOpen : status,
        },
      };
    case types.TOGGLE_ADD_BRANCH_MODAL:
      return {
        ...state,
        addBranch: {
          isOpen: !state.addBranch.isOpen,
        },
      };
    case types.TOGGLE_EDIT_BRANCH_MODAL:
      return {
        ...state,
        editBranch: {
          isOpen: !state.editBranch.isOpen,
        },
      };
    case types.TOGGLE_EDIT_BRANCH_REQUEST_MODAL:
      return {
        ...state,
        editBranchRequest: {
          isOpen: !state.editBranchRequest.isOpen,
        },
      };
    case types.TOGGLE_CHANGE_PASSWORD_MODAL:
      return {
        ...state,
        changePassword: {
          isOpen: !state.changePassword.isOpen,
          formDescription: action.payload ? action.payload.formDescription : null,
        },
      };
    case types.TOGGLE_EDIT_CLINIC_MODAL:
      return {
        ...state,
        editClinic: {
          isOpen: !state.editClinic.isOpen,
        },
      };
    case types.TOGGLE_EDIT_CLINIC_REQUEST_MODAL:
      return {
        ...state,
        editClinicRequest: {
          isOpen: !state.editClinicRequest.isOpen,
        },
      };
    case types.TOGGLE_EDIT_SYSTEMOWNER_MODAL:
      return {
        ...state,
        editSystemOwner: {
          isOpen: !state.editSystemOwner.isOpen,
        },
      };
    case types.TOGGLE_ADD_DOCTOR_MODAL:
      return {
        ...state,
        addDoctor: {
          isOpen: !state.addDoctor.isOpen,
        },
      };
    case types.TOGGLE_ADD_DOCTOR_BUSY_TIME_MODAL:
      return {
        ...state,
        addDoctorBusyTime: {
          isOpen: !state.addDoctorBusyTime.isOpen,
        },
      };
    case types.TOGGLE_BRANCH_IMAGES_MODAL: {
      return {
        ...state,
        branchImages: {
          isOpen: !state.branchImages.isOpen,
        },
      };
    }
    case types.TOGGLE_ADD_BRANCH_WORKING_HOURS_MODAL: {
      return {
        ...state,
        addBranchWorkingHours: {
          isOpen: !state.addBranchWorkingHours.isOpen,
        },
      };
    }
    case types.TOGGLE_EDIT_PATIENT_MODAL: {
      return {
        ...state,
        editPatient: {
          isOpen: !state.editPatient.isOpen,
        },
      };
    }
    case types.TOGGLE_ADD_PATIENT_MODAL: {
      return {
        ...state,
        addPatient: {
          isOpen: !state.addPatient.isOpen,
        },
      };
    }
    case types.TOGGLE_EDIT_PATIENT_NUMBER_MODAL: {
      return {
        ...state,
        editPatientNumber: {
          isOpen: !state.editPatientNumber.isOpen,
        },
      };
    }
    case types.TOGGLE_BLOCK_PATIENT_MODAL: {
      return {
        ...state,
        blockPatient: {
          isOpen: !state.blockPatient.isOpen,
        },
      };
    }
    case types.TOGGLE_UNBLOCK_PATIENT_MODAL: {
      return {
        ...state,
        unblockPatient: {
          isOpen: !state.unblockPatient.isOpen,
        },
      };
    }
    case types.TOGGLE_EDIT_PROFILE_MODAL: {
      return {
        ...state,
        editProfile: {
          isOpen: !state.editProfile.isOpen,
        },
      };
    }
    case types.TOGGLE_EXPORT_TO_XLS_MODAL: {
      return {
        ...state,
        exportToXLS: {
          isOpen: !state.exportToXLS.isOpen,
        },
      };
    }
    case types.TOGGLE_DELETED_DOCTOR_MODAL: {
      return {
        ...state,
        deletedDoctor: {
          isOpen: !state.deletedDoctor.isOpen,
        },
      };
    }
    case types.TOGGLE_REJECTED_CLINIC_INFO: {
      return {
        ...state,
        rejectedClinicInfo: {
          isOpen: !state.rejectedClinicInfo.isOpen,
        },
      };
    }
    case types.TOGGLE_ADD_USER_ROLE_MODAL: {
      return {
        ...state,
        addUserRole: {
          isOpen: !state.addUserRole.isOpen,
        },
      };
    }
    case types.TOGGLE_EDIT_USER_ROLE_MODAL: {
      return {
        ...state,
        editUserRole: {
          isOpen: !state.editUserRole.isOpen,
        },
      };
    }
    case types.TOGGLE_CANCEL_PENDING_CLINIC: {
      return {
        ...state,
        cancelPendingClinic: {
          isOpen: !state.cancelPendingClinic.isOpen,
        },
      };
    }
    case types.TOGGLE_REJECT_PENDING_CLINIC: {
      return {
        ...state,
        rejectPendingClinic: {
          isOpen: !state.rejectPendingClinic.isOpen,
        },
      };
    }
    case types.TOGGLE_ADD_PRODUCT: {
      return {
        ...state,
        addProduct: {
          isOpen: !state.addProduct.isOpen,
        },
      };
    }
    case types.TOGGLE_CONFIRMATION_MODAL: {
      return {
        ...state,
        confirmation: {
          onConfirm: action.payload.onConfirm,
          translationKey: action.payload.translationKey || state.confirmation.translationKey,
          isOpen: !state.confirmation.isOpen,
        },
      };
    }
    case types.TOGGLE_ADD_PRODUCT_MODAL: {
      return {
        ...state,
        addProduct: {
          isOpen: !state.addProduct.isOpen,
        },
      };
    }
    case types.TOGGLE_BRANCHES_MODAL: {
      return {
        ...state,
        branches: {
          isOpen: !state.branches.isOpen,
        },
      };
    }
    case types.TOGGLE_EDIT_PRODUCT_MODAL: {
      return {
        ...state,
        editProduct: {
          isOpen: !state.editProduct.isOpen,
        },
      };
    }
    case types.TOGGLE_DELETED_BRANCH_MODAL: {
      return {
        ...state,
        deletedBranch: {
          isOpen: !state.deletedBranch.isOpen,
        },
      };
    }
    case types.TOGGLE_DOCTOR_WORKING_HOURS_MODAL: {
      return {
        ...state,
        doctorWorkingHours: {
          isOpen: !state.doctorWorkingHours.isOpen,
        },
      };
    }
    case types.TOGGLE_ADD_DRAFT_ITEM_MODAL: {
      return {
        ...state,
        addDraftItem: {
          isOpen: !state.addDraftItem.isOpen,
        },
      };
    }
    case types.TOGGLE_ADD_INVOICE_ITEM_MODAL: {
      return {
        ...state,
        addInvoiceItem: {
          isOpen: !state.addInvoiceItem.isOpen,
        },
      };
    }

    case types.TOGGLE_EDIT_DRAFT_ITEM_MODAL: {
      return {
        ...state,
        editDraftItem: {
          isOpen: !state.editDraftItem.isOpen,
        },
      };
    }
    case types.TOGGLE_EDIT_INVOICE_ITEM_MODAL: {
      return {
        ...state,
        editInvoiceItem: {
          isOpen: !state.editInvoiceItem.isOpen,
        },
      };
    }
    case types.TOGGLE_ADD_PATIENT_TO_CLINIC_MODAL: {
      return {
        ...state,
        addPatientToClinic: {
          isOpen: !state.addPatientToClinic.isOpen,
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
};
