import { ChangeEvent, ReactNode, useCallback, useMemo } from 'react';
import { FormatOptionLabelMeta } from 'react-select/dist/declarations/src/Select';
import { Alert, Button, Col, Row } from 'reactstrap';
import { RowProps } from 'reactstrap/types/lib/Row';
import { BaseFieldProps, Field, Form, reduxForm } from 'redux-form';
import { EventWithDataHandler } from 'redux-form/lib/Field';
import { InjectedFormProps } from 'redux-form/lib/reduxForm';
import {
  getTranslation,
  useAppDispatch,
  useAppIntl,
  useAppSelector,
  useCurrentUserPermissions,
  useDate,
  useDictionaries,
  usePayByOptions,
} from 'app/helpers';
import {
  AsyncSelectComponent,
  DatePickerComponent,
  DatePickerComponentProps,
  FORMS_INVOICING_PROCESS_PAYMENT_MODE,
  InputComponent,
  PRICE_VALUE_MAX,
  PRICE_VALUE_MIN,
  SelectComponent,
} from 'app/shared';
import { fetchDropdownOptions } from 'app/redux/dropdownOptions/dropdownOptions.actions';
import {
  fetchInsuranceApprovalItems,
  fetchInsuranceReferralItems,
  resetItems,
} from 'app/redux/invoicingProcess/invoicingProcess.actions';
import { InvoicingProcess } from 'app/redux/invoicingProcess/types';
import { toggleModal } from 'app/redux/modals/modals.actions';
import {
  ErrorType,
  InvoiceEligibility,
  InvoicePayBy,
  Option,
  ReferralInsuranceApprovalOption,
  RemoteDataParams,
} from 'app/types';
import {
  checkIsInsuranceApprovalNullByDefault,
  checkIsOldInvoice,
  useInvoicingProcessState,
} from 'app/features/invoicingProcess/process/helpers';
import { mapInsuranceClassPatientSharesToFields } from 'app/features/invoicingProcess/process/helpers/mapInsuranceClassPatientSharesToFields';
import { MaxPatientShareField } from 'app/features/invoicingProcess/process/paymentMode/components/maxPatientShareField';
import { PatientShareField } from 'app/features/invoicingProcess/process/paymentMode/components/patientShareField';
import { useFetchOptions } from 'app/features/invoicingProcess/process/paymentMode/form/helpers/useFetchOptions';
import { PaymentModeField, PaymentModeFormData } from 'app/features/invoicingProcess/process/paymentMode/form/types';
import { validate } from 'app/features/invoicingProcess/process/paymentMode/form/validate';

export const paymentModeSubmitButtonId = 'invoicingProcessPaymentModeSubmit';

export interface Props {
  appointmentId: string | null;
  invoicingProcess: InvoicingProcess | null;
}

const FormComponent = ({
  appointmentId,
  change,
  form,
  handleSubmit,
  invoicingProcess,
}: InjectedFormProps<PaymentModeFormData, Props, ErrorType> & Props) => {
  const { todayStart } = useDate();
  const dispatch = useAppDispatch();
  const { formatMessage, locale } = useAppIntl();
  const {
    'insurance-approval-types': approvalTypeOptions,
    'eligibility-types': eligibilityTypes,
    'government-issued-id-types': governmentIssuedTypeOptions,
  } = useDictionaries();
  const payByOptions = usePayByOptions();
  const { requestId, isCreatedAndNotCreateCreditNoteMode, isCreateCreditNoteMode, invoicePackageSnapshotId } =
    useInvoicingProcessState();
  const branchId: string | undefined = useAppSelector((state) => state.invoicingProcess.data?.clinicBranch.value);
  const patientId = useAppSelector((state) => state.invoicingProcess.data?.patient?.id);
  const guardianPatientId = useAppSelector((state) => state.invoicingProcess.data?.guardianPatient?.id);
  const invoiceType = useAppSelector((state) => state.invoicingProcess.data?.invoiceType?.value);
  const insuranceCompanyCacheUniq = useAppSelector((state) => state.invoicingProcess.insuranceCompanyCacheUniq);
  const isOldInvoice = checkIsOldInvoice(invoiceType);
  const visitNumber = useAppSelector((state) => state.invoicingProcess.data?.visitNumber);
  const { allowedForInsuranceManagement } = useCurrentUserPermissions();
  const eligibility: PaymentModeFormData['eligibility'] | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[PaymentModeField.eligibility],
  );
  const insuranceApproval: PaymentModeFormData['insuranceApproval'] | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[PaymentModeField.insuranceApproval],
  );
  const insuranceReferralApproval: PaymentModeFormData['insuranceReferralApproval'] | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[PaymentModeField.insuranceReferralApproval],
  );
  const payBay: PaymentModeFormData['payBy'] | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[PaymentModeField.payBy],
  );
  const insuranceCompany: PaymentModeFormData['insuranceCompany'] | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[PaymentModeField.insuranceCompany],
  );
  const insurancePolicy: PaymentModeFormData['policyNumber'] | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[PaymentModeField.policyNumber],
  );
  const isPrimaryCareSpeciality = useAppSelector(
    (state) => !!state.invoicingProcess.data?.doctor?.details?.isPrimaryCareSpeciality,
  );
  const isPayByInsurance = payBay?.value === InvoicePayBy.Insurance;
  const isPayByPatient = payBay?.value === InvoicePayBy.Patient;
  const isEligible = eligibility?.value === InvoiceEligibility.Eligible;
  const isReferralIn = eligibility?.value === InvoiceEligibility.ReferralIn;
  const isPreviousApproval = eligibility?.value === InvoiceEligibility.PreviousApproval;
  const isInsuranceApprovalNullByDefault = checkIsInsuranceApprovalNullByDefault(
    invoicingProcess?.steps?.paymentMode?.insurance,
  );
  const hasInsuranceApprovalField = isPreviousApproval && !isInsuranceApprovalNullByDefault;
  const hasRequiredEligibilityFields =
    !!eligibility && (!!insuranceReferralApproval || !!insuranceApproval || isEligible); // Eligibility field and the related field are selected
  const hasInsuranceFields =
    isPayByInsurance && (hasRequiredEligibilityFields || isInsuranceApprovalNullByDefault || !appointmentId);
  const disabled = isCreatedAndNotCreateCreditNoteMode || !!invoicePackageSnapshotId;
  const rowProps: RowProps = { xs: 1, lg: 2, xl: 3, xxl: 4 };

  const eligibilityOptions = useMemo<Option<InvoiceEligibility>[] | undefined>(() => {
    if (!visitNumber) {
      return eligibilityTypes?.filter((e) => e.value !== InvoiceEligibility.PreviousApproval);
    }
    return eligibilityTypes;
  }, [eligibilityTypes, visitNumber]);

  // Helper Hooks
  const { fetchInsurancePoliciesOptions, fetchInsuranceClassesOptions } = useFetchOptions({
    insuranceCompany,
    insurancePolicy,
  });

  const formatInsuranceReferralOptionLabel = useCallback<
    (
      data: ReferralInsuranceApprovalOption,
      formatOptionLabelMeta: FormatOptionLabelMeta<ReferralInsuranceApprovalOption>,
    ) => ReactNode
  >(
    (option, formatOptionLabelMeta) => {
      if (formatOptionLabelMeta.context === 'value') {
        return option.label;
      }

      return (
        <>
          {formatMessage({ id: 'CORE.TABLE.REFERENCE-NUMBER' })}: <span className="fw-medium">{option.label}</span>
          <div>
            {formatMessage({ id: 'CORE.LABEL.CLINICY-ID' })}:{' '}
            <span className="fw-medium">{option.details?.submissionClinicyId}</span>
          </div>
          <div>
            {formatMessage({ id: 'CORE.LABEL.INSURANCE-COMPANY' })}:{' '}
            <span className="fw-medium">{getTranslation(option.details?.insuranceCompany, locale)}</span>
          </div>
        </>
      );
    },
    [formatMessage, locale],
  );

  const fetchInsuranceCompanyOptions = useCallback(
    (params: RemoteDataParams) => {
      return dispatch(
        fetchDropdownOptions(`/clinic-patients/${guardianPatientId || patientId}/insurances/activated/dropdown`, {
          ...params,
          clinicBranchId: branchId,
        }),
      );
    },
    [dispatch, guardianPatientId, patientId, branchId],
  );

  const fetchReferralInsuranceApprovalOptions = useCallback(
    (params: RemoteDataParams) => {
      return dispatch(
        fetchDropdownOptions(`/appointments/${appointmentId}/referral-insurance-approvals/invoice-dropdown`, params),
      );
    },
    [appointmentId, dispatch],
  );

  const fetchInsuranceApprovalOptions = useCallback(
    (params: RemoteDataParams) => {
      return dispatch(
        fetchDropdownOptions(`/appointments/${appointmentId}/insurance-approvals/approved/dropdown`, params),
      );
    },
    [appointmentId, dispatch],
  );

  const fetchInsuranceItems = useCallback(
    (insuranceClassId: string) => {
      if (isReferralIn || isPreviousApproval) {
        if (isReferralIn && insuranceReferralApproval) {
          dispatch(resetItems());
          dispatch(fetchInsuranceReferralItems(insuranceReferralApproval.value, insuranceClassId));
        }
        if (isPreviousApproval && insuranceApproval && !isInsuranceApprovalNullByDefault) {
          dispatch(resetItems());
          dispatch(fetchInsuranceApprovalItems(insuranceApproval.value));
        }
      }
    },
    [
      dispatch,
      insuranceApproval,
      insuranceReferralApproval,
      isInsuranceApprovalNullByDefault,
      isPreviousApproval,
      isReferralIn,
    ],
  );

  const onPayByChange = useCallback<EventWithDataHandler<ChangeEvent>>(
    (_, newValue?: PaymentModeFormData['payBy']) => {
      dispatch(resetItems());

      if (newValue?.value === InvoicePayBy.Patient) {
        change(PaymentModeField.eligibility, null);
      }
    },
    [change, dispatch],
  );

  const onEligibilityChange = useCallback<EventWithDataHandler<ChangeEvent>>(
    (_, newValue?: PaymentModeFormData['eligibility']) => {
      dispatch(resetItems());

      if (newValue) {
        change(PaymentModeField.insuranceReferralApproval, null);
        change(PaymentModeField.insuranceApproval, null);
        change(PaymentModeField.insuranceCompany, null);
      }
    },
    [change, dispatch],
  );

  const onRelatedToEligibilityChange = useCallback<EventWithDataHandler<ChangeEvent>>(
    (_, newValue?: Option) => {
      if (newValue) {
        change(PaymentModeField.insuranceCompany, null);
      }
    },
    [change],
  );

  const onInsuranceCompanyChange = useCallback<EventWithDataHandler<ChangeEvent>>(
    (_, newValue?: PaymentModeFormData['insuranceCompany']) => {
      if (newValue) {
        const patientShares = newValue.details.insuranceClass.details?.patientShares;

        // Fields
        change(PaymentModeField.end, new Date(newValue.details.end));
        change(PaymentModeField.insuranceClass, newValue.details.insuranceClass);
        change(PaymentModeField.insuranceNumber, newValue.details.number);
        change(PaymentModeField.policyNumber, newValue.details.insurancePolicy);
        change(PaymentModeField.start, new Date(newValue.details.start));

        if (patientShares) {
          // Set patient share values
          const shares = mapInsuranceClassPatientSharesToFields(patientShares);
          change(
            PaymentModeField.nonReplaceableBrandMedicationsMaxPatientShare,
            shares.nonReplaceableBrandMedicationsMaxPatientShare,
          );
          change(
            PaymentModeField.nonReplaceableBrandMedicationsPatientShare,
            shares.nonReplaceableBrandMedicationsPatientShare,
          );
          change(PaymentModeField.primaryClinicMaxPatientShare, shares.primaryClinicMaxPatientShare);
          change(PaymentModeField.primaryClinicPatientShare, shares.primaryClinicPatientShare);
          change(
            PaymentModeField.referralToSpecializedClinicMaxPatientShare,
            shares.referralToSpecializedClinicMaxPatientShare,
          );
          change(
            PaymentModeField.referralToSpecializedClinicPatientShare,
            shares.referralToSpecializedClinicPatientShare,
          );
          change(
            PaymentModeField.replaceableBrandMedicationsMaxPatientShare,
            shares.replaceableBrandMedicationsMaxPatientShare,
          );
          change(
            PaymentModeField.replaceableBrandMedicationsPatientShare,
            shares.replaceableBrandMedicationsPatientShare,
          );
          change(PaymentModeField.specializedClinicMaxPatientShare, shares.specializedClinicMaxPatientShare);
          change(PaymentModeField.specializedClinicPatientShare, shares.specializedClinicPatientShare);
        }

        // Fetch insurance items
        fetchInsuranceItems(newValue.details.insuranceClass.value);
      }
    },
    [change, fetchInsuranceItems],
  );

  const openInsuranceDetailsModal = useCallback(() => {
    dispatch(toggleModal('invoicingProcessInsuranceDetails', true));
  }, [dispatch]);

  const isInsuranceCompanyOptionDisabled = useCallback(
    (option: PaymentModeFormData['insuranceCompany']): boolean => {
      if (isReferralIn && insuranceReferralApproval) {
        // Disable all options that are not compatible with referral insurance company (PT-8745)
        return option.details.insuranceCompany.id !== insuranceReferralApproval?.details?.insuranceCompany.value;
      }

      // All options are enabled
      return false;
    },
    [insuranceReferralApproval, isReferralIn],
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Row {...rowProps}>
        <Col>
          <Field
            disabled={disabled || isCreateCreditNoteMode || !!requestId}
            name={PaymentModeField.payBy}
            component={SelectComponent}
            label={formatMessage({ id: 'CORE.LABEL.PAY-BY', defaultMessage: 'Pay by' })}
            onChange={onPayByChange}
            options={payByOptions}
            isRequired
          />
        </Col>
        {isPayByPatient && (
          <>
            <Col>
              <Field
                name={PaymentModeField.governmentIssuedIdType}
                component={SelectComponent}
                disabled={disabled}
                label={formatMessage({ id: 'CORE.LABEL.GOVERNMENT-ID-TYPE' })}
                options={governmentIssuedTypeOptions}
                isRequired
              />
            </Col>
            <Col>
              <Field
                disabled={disabled}
                name={PaymentModeField.governmentIssuedId}
                component={InputComponent}
                label={formatMessage({ id: 'CORE.LABEL.GOVERNMENT-ID' })}
                type="text"
                isRequired
              />
            </Col>
          </>
        )}
        {isPayByInsurance && appointmentId && (
          <>
            <Col>
              <Field
                name={PaymentModeField.eligibility}
                component={SelectComponent}
                disabled={disabled || isCreateCreditNoteMode || !!requestId}
                label={formatMessage({ id: 'CORE.LABEL.ELIGIBILITY' })}
                onChange={onEligibilityChange}
                options={eligibilityOptions}
                isRequired
              />
            </Col>
            {isEligible && (
              <>
                <Col>
                  <Field
                    disabled={disabled}
                    name={PaymentModeField.eligibilityNumber}
                    component={InputComponent}
                    label={formatMessage({ id: 'CORE.LABEL.ELIGIBILITY-NUMBER' })}
                    type="text"
                    isRequired
                  />
                </Col>
                <Col>
                  <Button
                    className="mt-0 mt-lg-4 mb-3"
                    color="primary"
                    tag="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://eservices.chi.gov.sa/"
                    outline
                  >
                    {formatMessage({ id: 'CORE.LABEL.CHECK-ELIGIBILITY' })}
                  </Button>
                </Col>
              </>
            )}
            {isReferralIn && (
              <Col>
                <Field
                  disabled={disabled || isCreateCreditNoteMode}
                  name={PaymentModeField.insuranceReferralApproval}
                  component={AsyncSelectComponent}
                  fetchOptions={fetchReferralInsuranceApprovalOptions}
                  formatOptionLabel={formatInsuranceReferralOptionLabel}
                  onChange={onRelatedToEligibilityChange}
                  label={formatMessage({ id: 'CORE.LABEL.INSURANCE-REFERRAL-APPROVAL' })}
                  isRequired
                />
              </Col>
            )}
            {hasInsuranceApprovalField && (
              <Col>
                <Field
                  disabled={disabled || isCreateCreditNoteMode}
                  name={PaymentModeField.insuranceApproval}
                  component={AsyncSelectComponent}
                  fetchOptions={fetchInsuranceApprovalOptions}
                  onChange={onRelatedToEligibilityChange}
                  label={formatMessage({ id: 'CORE.TEXT.INSURANCE-APPROVAL' })}
                  isRequired
                />
              </Col>
            )}
          </>
        )}
      </Row>

      {/* Referral Insurance Company Warning */}
      {isPayByInsurance && isReferralIn && insuranceReferralApproval?.details?.insuranceCompany && (
        <Alert className="d-inline-block small px-2 py-1" color="warning">
          {formatMessage(
            { id: 'CORE.TEXT.REFERRAL-INSURANCE-COMPANY-INFO' },
            {
              insuranceCompany: (
                <b>
                  {insuranceReferralApproval.details
                    ? getTranslation(insuranceReferralApproval.details.insuranceCompany, locale)
                    : '-'}
                </b>
              ),
            },
          )}
        </Alert>
      )}

      {hasInsuranceFields && (
        <>
          <h5 className="mt-3 mb-4">
            {formatMessage({ id: 'CORE.TEXT.INSURANCE-PAYMENT', defaultMessage: 'Insurance Payment' })}
          </h5>
          <Row {...rowProps}>
            <Col>
              <Field
                name={PaymentModeField.governmentIssuedIdType}
                component={SelectComponent}
                disabled={disabled}
                label={formatMessage({ id: 'CORE.LABEL.GOVERNMENT-ID-TYPE' })}
                options={governmentIssuedTypeOptions}
                isRequired
              />
            </Col>
            <Col>
              <Field
                disabled={disabled}
                name={PaymentModeField.governmentIssuedId}
                component={InputComponent}
                label={formatMessage({ id: 'CORE.LABEL.GOVERNMENT-ID' })}
                type="text"
                isRequired
              />
            </Col>
            <Col>
              <Field
                disabled={disabled}
                name={PaymentModeField.insuranceCompany}
                cacheUniqs={[insuranceCompanyCacheUniq]}
                component={AsyncSelectComponent}
                fetchOptions={fetchInsuranceCompanyOptions}
                isOptionDisabled={isInsuranceCompanyOptionDisabled}
                isSearchable={false}
                label={formatMessage({
                  id: 'CORE.LABEL.INSURANCE-COMPANY',
                  defaultMessage: 'Insurance Company',
                })}
                onChange={onInsuranceCompanyChange}
                isRequired
              />
            </Col>
            {!isCreatedAndNotCreateCreditNoteMode && allowedForInsuranceManagement && (
              <Col>
                <Button className="mt-0 mt-lg-4 mb-3" color="primary" onClick={openInsuranceDetailsModal} outline>
                  {formatMessage({ id: 'CORE.LABEL.MANAGE-PATIENT-INSURANCE' })}
                </Button>
              </Col>
            )}
            {insuranceCompany && (
              <>
                <Col>
                  <Field
                    disabled
                    name={PaymentModeField.policyNumber}
                    cacheUniqs={[insuranceCompany?.value]}
                    component={AsyncSelectComponent}
                    fetchOptions={fetchInsurancePoliciesOptions}
                    label={formatMessage({
                      id: 'CORE.LABEL.POLICY-NUMBER',
                      defaultMessage: 'Policy Number',
                    })}
                    isRequired
                  />
                </Col>
                <Col>
                  <Field
                    disabled
                    name={PaymentModeField.insuranceNumber}
                    component={InputComponent}
                    label={formatMessage({
                      id: 'CORE.LABEL.INSURANCE-NUMBER',
                      defaultMessage: 'Insurance Number',
                    })}
                    type="text"
                    isRequired
                  />
                </Col>
                <Col>
                  <Field
                    disabled
                    name={PaymentModeField.insuranceClass}
                    component={AsyncSelectComponent}
                    cacheUniqs={[insurancePolicy?.value]}
                    fetchOptions={fetchInsuranceClassesOptions}
                    label={formatMessage({
                      id: 'CORE.LABEL.INSURANCE-CLASS',
                      defaultMessage: 'Insurance Class',
                    })}
                    isRequired
                  />
                </Col>
                <Col>
                  <Field<BaseFieldProps<DatePickerComponentProps>>
                    name={PaymentModeField.start}
                    component={DatePickerComponent}
                    props={{
                      disabled: true,
                      isRequired: true,
                      label: formatMessage({ id: 'CORE.LABEL.START-DATE' }),
                      maxDate: todayStart,
                    }}
                  />
                </Col>
                <Col>
                  <Field<BaseFieldProps<DatePickerComponentProps>>
                    name={PaymentModeField.end}
                    component={DatePickerComponent}
                    props={{
                      disabled: true,
                      isRequired: true,
                      label: formatMessage({ id: 'CORE.LABEL.END-DATE' }),
                      minDate: todayStart,
                    }}
                  />
                </Col>
                <Col>
                  <Field
                    disabled={disabled}
                    name={PaymentModeField.approvalType}
                    component={SelectComponent}
                    label={formatMessage({
                      id: 'CORE.LABEL.APPROVAL-TYPE',
                      defaultMessage: 'Approval Type',
                    })}
                    options={approvalTypeOptions}
                    isRequired
                  />
                </Col>
                <Col>
                  <Field
                    disabled={disabled}
                    name={PaymentModeField.benefitLimit}
                    appendContent={formatMessage({ id: 'CORE.TEXT.CURRENCY-SAR' })}
                    component={InputComponent}
                    label={formatMessage({
                      id: 'CORE.LABEL.BENEFIT-LIMIT',
                      defaultMessage: 'Benefit Limit',
                    })}
                    min={PRICE_VALUE_MIN}
                    max={PRICE_VALUE_MAX}
                    step={0.01}
                    type="number"
                  />
                </Col>
                <Col>
                  <Field
                    disabled={disabled}
                    name={PaymentModeField.maxConsultFee}
                    appendContent={formatMessage({ id: 'CORE.TEXT.CURRENCY-SAR' })}
                    component={InputComponent}
                    label={formatMessage({
                      id: 'CORE.LABEL.MAX-CONSULT-FEE',
                      defaultMessage: 'Max Consultation Fee',
                    })}
                    min={PRICE_VALUE_MIN}
                    max={PRICE_VALUE_MAX}
                    step={0.01}
                    type="number"
                  />
                </Col>
                <Col>
                  <Field
                    disabled={disabled}
                    name={PaymentModeField.approvalThreshold}
                    appendContent={formatMessage({ id: 'CORE.TEXT.CURRENCY-SAR' })}
                    component={InputComponent}
                    label={formatMessage({
                      id: 'CORE.LABEL.APPROVAL-THRESHOLD',
                      defaultMessage: 'Approval Threshold',
                    })}
                    min={PRICE_VALUE_MIN}
                    max={PRICE_VALUE_MAX}
                    step={0.01}
                    type="number"
                  />
                </Col>
              </>
            )}
          </Row>
          {insuranceCompany && (
            <>
              <h5 className="mt-3 mb-4">{formatMessage({ id: 'CORE.LABEL.PATIENT-SHARE' })}</h5>
              <Row {...rowProps}>
                {isOldInvoice && (
                  <>
                    <Col>
                      <PatientShareField
                        disabled={disabled}
                        label={formatMessage({ id: 'CORE.LABEL.PATIENT-SHARE' })}
                        name={PaymentModeField.patientShare}
                      />
                    </Col>
                    <Col>
                      <MaxPatientShareField
                        disabled={disabled}
                        label={formatMessage({ id: 'CORE.LABEL.MAX-PATIENT-SHARE' })}
                        name={PaymentModeField.maxPatientShare}
                      />
                    </Col>
                  </>
                )}
                {!isOldInvoice && isReferralIn && (
                  <>
                    <Col>
                      <PatientShareField
                        disabled={disabled}
                        label={formatMessage({ id: 'CORE.LABEL.PATIENT-SHARE' })}
                        name={PaymentModeField.referralToSpecializedClinicPatientShare}
                      />
                    </Col>
                    <Col>
                      <MaxPatientShareField
                        disabled={disabled}
                        label={formatMessage({ id: 'CORE.LABEL.MAX-PATIENT-SHARE' })}
                        name={PaymentModeField.referralToSpecializedClinicMaxPatientShare}
                      />
                    </Col>
                  </>
                )}
                {!isOldInvoice && !isReferralIn && isPrimaryCareSpeciality && (
                  <>
                    <Col>
                      <PatientShareField
                        disabled={disabled}
                        label={formatMessage({ id: 'CORE.LABEL.PATIENT-SHARE' })}
                        name={PaymentModeField.primaryClinicPatientShare}
                      />
                    </Col>
                    <Col>
                      <MaxPatientShareField
                        disabled={disabled}
                        label={formatMessage({ id: 'CORE.LABEL.MAX-PATIENT-SHARE' })}
                        name={PaymentModeField.primaryClinicMaxPatientShare}
                      />
                    </Col>
                  </>
                )}
                {!isOldInvoice && !isReferralIn && !isPrimaryCareSpeciality && (
                  <>
                    <Col>
                      <PatientShareField
                        disabled={disabled}
                        label={formatMessage({ id: 'CORE.LABEL.PATIENT-SHARE' })}
                        name={PaymentModeField.specializedClinicPatientShare}
                      />
                    </Col>
                    <Col>
                      <MaxPatientShareField
                        disabled={disabled}
                        label={formatMessage({ id: 'CORE.LABEL.MAX-PATIENT-SHARE' })}
                        name={PaymentModeField.specializedClinicMaxPatientShare}
                      />
                    </Col>
                  </>
                )}
              </Row>
              {!isOldInvoice && !isReferralIn && !isEligible && (
                <Row {...rowProps}>
                  {/* These fields are rendered at the same time and required custom labels */}
                  <Col>
                    <PatientShareField
                      disabled={disabled}
                      label={formatMessage({ id: 'CORE.LABEL.PATIENT-SHARE-FOR-REP-MEDICATIONS' })}
                      name={PaymentModeField.replaceableBrandMedicationsPatientShare}
                    />
                  </Col>
                  <Col>
                    <MaxPatientShareField
                      disabled={disabled}
                      label={formatMessage({ id: 'CORE.LABEL.MAX-PATIENT-SHARE-FOR-REP-MEDICATIONS' })}
                      name={PaymentModeField.replaceableBrandMedicationsMaxPatientShare}
                    />
                  </Col>
                  <Col>
                    <PatientShareField
                      disabled={disabled}
                      label={formatMessage({ id: 'CORE.LABEL.PATIENT-SHARE-FOR-NON-REP-MEDICATIONS' })}
                      name={PaymentModeField.nonReplaceableBrandMedicationsPatientShare}
                    />
                  </Col>
                  <Col>
                    <MaxPatientShareField
                      disabled={disabled}
                      label={formatMessage({ id: 'CORE.LABEL.MAX-PATIENT-SHARE-FOR-NON-REP-MEDICATIONS' })}
                      name={PaymentModeField.nonReplaceableBrandMedicationsMaxPatientShare}
                    />
                  </Col>
                </Row>
              )}
            </>
          )}
        </>
      )}
      <button className="visually-hidden" id={paymentModeSubmitButtonId} type="submit" />
    </Form>
  );
};

export const PaymentModeForm = reduxForm<PaymentModeFormData, Props, ErrorType>({
  destroyOnUnmount: false,
  form: FORMS_INVOICING_PROCESS_PAYMENT_MODE,
  validate: (values, props) =>
    validate(values, { appointmentId: props.appointmentId, invoicingProcess: props.invoicingProcess }),
})(FormComponent);
