import { put, select, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import toast from 'react-hot-toast';
import {
  ADD_CLINIC_SUCCESS,
  EDIT_CLINIC_SUCCESS,
  EDIT_SYSTEM_OWNER_SUCCESS,
  FETCH_CLINIC_SUCCESS,
  REJECT_CLINIC_SUCCESS,
} from 'app/redux/clinic/clinic.types';
import { APPROVE_CLINIC_SUCCESS } from 'app/redux/clinics-pending/clinicsPending.types';
import { fetchClinicsPending } from 'app/redux/clinics-pending/clinicsPending.actions';
import {
  toggleEditClinicModal,
  toggleEditSystemOwnerModal,
  toggleRejectPendingClinic,
} from 'app/redux/modals/modals.actions';
import { fetchClinic, fetchClinicSystemOwner } from 'app/redux/clinic/clinic.actions';
import { refreshClinicList } from 'app/sagas/clinic/generators/refreshClinicList';
import { refreshRejectedClinicList } from 'app/sagas/clinic/generators/refreshRejectedClinicList';

export function* afterAddClinicSuccess(action) {
  yield refreshClinicList(action);
}

export function* afterEditClinicSystemOwnerSuccess(action) {
  yield put(toggleEditSystemOwnerModal());
  yield put(fetchClinicSystemOwner(action.meta.clinicId));
}

export function* afterEditClinicSuccess({ payload }) {
  const {
    modal: {
      editClinic: { isOpen: isEditClinicOpen },
    },
  } = yield select();

  yield put(fetchClinic(payload.data.id));

  if (isEditClinicOpen) {
    yield put(toggleEditClinicModal());
  }
}

export function* afterRejectClinicSuccess() {
  yield put(toggleRejectPendingClinic());
  yield refreshRejectedClinicList();
  yield put(push('/clinics/rejected'));
}

export function* afterApproveClinicSuccess() {
  const {
    clinicsPending: { params },
  } = yield select();

  yield toast.success('CLINICS.NOTIFICATIONS.APPROVE-SUCCESS');
  yield put(fetchClinicsPending(params));
  yield put(push('/clinics'));
}

export function* afterFetchClinicSuccess(action) {
  if (action.meta !== undefined) {
    const { clinicId } = action.meta;
    yield put(fetchClinicSystemOwner(clinicId));
  }
}

function* clinicSaga() {
  yield takeEvery(ADD_CLINIC_SUCCESS, afterAddClinicSuccess);
  yield takeEvery(EDIT_CLINIC_SUCCESS, afterEditClinicSuccess);
  yield takeEvery(REJECT_CLINIC_SUCCESS, afterRejectClinicSuccess);
  yield takeEvery(APPROVE_CLINIC_SUCCESS, afterApproveClinicSuccess);
  yield takeEvery(FETCH_CLINIC_SUCCESS, afterFetchClinicSuccess);
  yield takeEvery(EDIT_SYSTEM_OWNER_SUCCESS, afterEditClinicSystemOwnerSuccess);
}

export default clinicSaga;
