import { useMemo } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { useAppIntl } from 'app/helpers';
import { ICD10Code } from 'app/types';

export const useIcdCodesColumns = (isPrint?: boolean) => {
  const { formatMessage } = useAppIntl();

  return useMemo(
    (): ColumnDescription<ICD10Code>[] => [
      {
        dataField: 'icd10Code',
        text: formatMessage({ id: 'TREATMENT-PLAN.LABEL.ICD10-CODE' }),
        formatter: (_, row) => row.icd10Code,
        style: { fontSize: isPrint ? '9px' : '14px', color: 'black', width: '50%' },
        headerStyle: { fontSize: isPrint ? '12px' : '15px', color: 'black', width: '50%' },
      },
      {
        dataField: 'description',
        text: formatMessage({ id: 'INVOICES.TEXT.ACTION-DESCRIPTION' }),
        formatter: (_, row) => row.description,
        style: { fontSize: isPrint ? '9px' : '14px', color: 'black', width: '50%' },
        headerStyle: { fontSize: isPrint ? '12px' : '15px', color: 'black', width: '50%' },
      },
    ],
    [formatMessage, isPrint],
  );
};
