import { Option } from './Option';
import { RemoteFile } from './RemoteFile';

export enum MedicalInstitutionStatus {
  Online = '0',
  Blocked = '2',
  Offline = '3',
  Deleted = '4',
  //Note: this is how backend sends Pending status, maybe we can prepare
  //task in the future for backend
  Pending = 'pendingRegistrationApproval',
}

export type MedicalInstitutionOwner = {
  crExpiryDate: string;
  crFile: RemoteFile;
  crNumber: string;
  email: string;
  nameAr: string;
  nameEn: string;
  officePhoneNumber: string;
  phoneNumber: string;
};

export type MedicalInstitutionSystemOwner = {
  email: string;
  id: string;
  nameAr: string;
  nameEn: string;
  nationalId: string;
  nationalIdFile: RemoteFile;
  officePhoneNumber: string;
  phoneNumber: string;
  filtersDefaults: {
    defaultBranch: Option | null;
    defaultSpeciality: Option | null;
  } | null;
};

export type MedicalInstitution = {
  allowedForEMR: boolean;
  allowedForInsuranceManagement: boolean;
  approvalDate: string | null;
  city: Option;
  clinicType: Option;
  clinicyId: string;
  declaredNumberOfBranches: number;
  descriptionAr: string;
  descriptionEn: string;
  id: string;
  isGrandFatherNameRequiredForPatients: boolean;
  isFatherNameRequiredForPatients: boolean;
  licenseNumber?: string;
  logo: RemoteFile;
  managementSystem: Option;
  mode: Option;
  nameAr: string;
  nameEn: string;
  numberOfClinicUsers: number;
  numberOfDoctors?: number;
  numberOfPatients?: number;
  numberOfRegisteredBranches?: number;
  otherClinicType: string | null;
  otherManagementSystem?: string | null;
  owner: MedicalInstitutionOwner;
  ownerAllowedForCreateOrders: boolean;
  ownerAllowedForTreatmentView: boolean;
  ownerAllowedForUpdateOrders: boolean;
  ownerAllowedForOrders: boolean;
  protectPhoneNumber: boolean;
  registrationDate: string;
  requireSaudiIdDuringPatientRegistration: boolean;
  status: Option<MedicalInstitutionStatus>;
  vatNumber: string;
};

export interface RejectedMedicalInstitution extends MedicalInstitution {
  rejectedByClinicyId: string;
  rejectedAt: string;
  reason: string;
}

export interface MedicalInstitutionRequest {
  action?: string;
  actionDate?: string;
  actionTakerClinicyId?: string;
  branchChanges?: BranchAndClinicChanges;
  clinicChanges?: BranchAndClinicChanges;
  clinic: Option;
  id: string;
  number: string;
  numberOfEditedFields: number;
  requestCreationTime: string;
  requestMakerClinicyId: string;
  status: string;
  type: string;
}

export interface BranchAndClinicChanges {
  id: string;
  old: Object;
  new: Object;
}
