import { useMemo } from 'react';
import { generalApi } from 'app/services/general/general';
import { Dictionaries } from 'app/types';

export const useDictionaries = () => {
  const { data } = generalApi.endpoints?.getDictionaries.useQueryState(undefined);

  return useMemo<Partial<Dictionaries>>(() => {
    if (data) {
      return data;
    }

    return {};
  }, [data]);
};
