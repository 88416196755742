import { useDictionaries } from 'app/helpers';

export const useOptions = () => {
  // Selectors - Dictionaries
  const {
    'martial-statuses': martialStatusOptions,
    'notification-languages': languageOptions,
    'emergency-contact-relations': emergencyRelations,
    'government-issued-id-types': governmentIssuedTypeOptions,
    cities: cityOptions,
    genders: genderOptions,
    nationalities: nationalityOptions,
    occupations: occupationOptions,
  } = useDictionaries();

  return {
    cityOptions,
    genderOptions,
    languageOptions,
    martialStatusOptions,
    nationalityOptions,
    occupationOptions,
    emergencyRelations,
    governmentIssuedTypeOptions,
  };
};
